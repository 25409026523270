import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import HeroSection from "./HeroSection";
import AboutUs from "./AboutUs";
import Testimonials from "./Testimonials";
import Gallery from "./Gallery";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import BottomNavBar from "../../components/BottomNavbar";


const Home = () => {
  const [permissionStatus, setPermissionStatus] = useState(null);

  useEffect(() => {
    const checkGeolocationPermission = async () => {
      if (navigator.permissions) {
        try {
          const result = await navigator.permissions.query({ name: "geolocation" });
          setPermissionStatus(result.state);
          result.onchange = () => {
            setPermissionStatus(result.state);
          };
        } catch (err) {
          console.error("Error checking location permissions.");
        }
      } else {
        console.error("Permissions API not supported in this browser.");
      }
    };

    checkGeolocationPermission();
  }, []);

  return (
    <Box>
      <Navbar />
      <BottomNavBar />
      <HeroSection />
      <AboutUs />
      <Testimonials />
      <Gallery />
      <Footer />

    </Box>
  );
};

export default Home;






// import React, { useEffect, useState } from "react";
// import { Box } from "@mui/material";
// import HeroSection from "./HeroSection";
// import AboutUs from "./AboutUs";
// import Testimonials from "./Testimonials";
// import Gallery from "./Gallery";

// const Home = () => {
//   const [location, setLocation] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const requestLocation = () => {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setLocation({ latitude, longitude });
//         },
//         (error) => {
//           switch (error.code) {
//             case error.PERMISSION_DENIED:
//               setError("User denied the request for Geolocation.");
//               break;
//             case error.POSITION_UNAVAILABLE:
//               setError("Location information is unavailable.");
//               break;
//             case error.TIMEOUT:
//               setError("The request to get user location timed out.");
//               break;
//             case error.UNKNOWN_ERROR:
//             default:
//               setError("An unknown error occurred.");
//               break;
//           }
//         }
//       );
//     };

//     if (navigator.permissions) {
//       navigator.permissions.query({ name: "geolocation" }).then((result) => {
//         if (result.state === "granted") {
//           requestLocation();
//         } else if (result.state === "prompt") {
//           requestLocation();
//         } else if (result.state === "denied") {
//           setError("Location access has been denied. Please enable it in your browser settings.");
//         }
//       });
//     } else {
//       requestLocation();
//     }
//   }, []);

//   return (
//     <Box>
//       <HeroSection />
//       <AboutUs />
//       <Testimonials />
//       <Gallery />

//       {location && (
//         <p>
//           Latitude: {location.latitude}, Longitude: {location.longitude}
//         </p>
//       )}
//       {error && <p style={{ color: "red" }}>{error}</p>}
//     </Box>
//   );
// };

// export default Home;

