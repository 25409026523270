import React, { useContext, useState } from 'react';
import { Box, Modal, Typography } from "@mui/material";
import { X } from "lucide-react";
import { Context } from '../../context/Context';
import useResponsive from '../../hooks/useResponsive';

const ServicePopup = () => {
    const { handleCloseServicePopup, openServicePopUp, services, handleOpenCategoryPopUp } = useContext(Context);

    const { isSmallScreen, isMediumScreen } = useResponsive();
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "600px",
        height: "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        outline: "none",
        borderRadius: "6px",
    };

    // const handleServiceClick = async (service) => {
    //     const getMaidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};
    //     const updatedMaidDetails = { ...getMaidDetails, serviceId: service.id };
    //     localStorage.setItem('maidDetails', JSON.stringify(updatedMaidDetails));
    //     getServicesFields(service.id);
    //     handleCloseServicePopup();
    //     handleOpenCategoryPopUp();
    // };

    const handleServiceClick = async (service) => {
        const getMaidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};
        const updatedMaidDetails = { ...getMaidDetails, serviceId: service.id };
        localStorage.setItem('maidDetails', JSON.stringify(updatedMaidDetails));

        // const fields = await getServicesFields(service.id);
        // if (fields && fields.serviceCategory && fields.serviceCategory.length > 0) {
        //     handleOpenCategoryPopUp();
        // } else {
        handleOpenCategoryPopUp();
        // }
        // handleCloseServicePopup();
    };


    return (
        <Box>
            <Modal open={openServicePopUp} onClose={handleCloseServicePopup}>
                <Box sx={modalStyle}>
                    <Box sx={{ padding: "40px" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                What are you looking for?
                            </Typography>
                            <X cursor="pointer" onClick={handleCloseServicePopup} />
                        </Box>


                        <Box sx={{
                            display: "flex", flexWrap: "wrap", gap: "15px", width: isSmallScreen ? "280px" : isMediumScreen ? "400px" : "500px",
                        }}>
                            {services.slice(0,8).map((item) => (
                                <Box key={item.id} sx={{ display: "flex", flexDirection: "column", textAlign: "center", gap: "2px" }} onClick={() => handleServiceClick(item)}
                                >
                                    <Box sx={{
                                        border: "1px solid #BCBCBC",  padding: isSmallScreen ? "7px 50px" : isMediumScreen ? "10px 50px" : "7px 40px",
                                        borderRadius: "10px", cursor: "pointer",
                                    }} >
                                        <img src={item.imagePath} width="30px" height="30px" alt="not found" />
                                    </Box>
                                    <Typography variant="h6" sx={{ color: "#000000" }}>{item.name}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Modal>

        </Box>
    );
};

export default ServicePopup;