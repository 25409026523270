import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CardContent, Button, Typography, Box, Divider, Tooltip, IconButton, Container } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import { useNavigate, useParams } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimerIcon from '@mui/icons-material/Timer';
import { ArrowLeftIcon, Info } from 'lucide-react';
import BookingCancellation from '../../../popup/BookingPopUp/BookingCancellation';
import ReschedulePopup from '../../../popup/Reschedulepopup';
import moneyBadge from '../../../Assets/Images/moneyBadge.png';
import apiUrl from "../../../config";
import Navbar from '../../../components/Navbar';
import BottomNavBar from '../../../components/BottomNavbar';

const BookingDetails = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();
    const { id } = useParams();
    const navigate = useNavigate();

    const [booking, setBooking] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCancellationOpen, setCancellationOpen] = useState(false);
    const [isRescheduleOpen, setRescheduleOpen] = useState(false);
    const [showBreakup, setShowBreakup] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const response = await axios.get(`${apiUrl}booking/${id}`);
                setBooking(response.data);
            } catch (err) {
                setError('Failed to load booking details. Please try again.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchBookingDetails();
    }, [id]);
    console.log(booking)

    const toggleBreakup = () => {
        setShowBreakup((prev) => !prev);
    };

    const handleHelpClick = () => {
        navigate('/faq');
    };

    const handleCancelBookingClick = () => {
        setCancellationOpen(true);
    };

    const handleCloseCancellation = () => {
        setCancellationOpen(false);
    };

    const handleRescheduleClick = () => {
        setRescheduleOpen(true);
    };

    const handleCloseReschedule = () => {
        setRescheduleOpen(false);
    };

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!booking) {
        return <Box>Booking not found</Box>;
    }

    return (
        <>
            <Navbar />
            <BottomNavBar />

            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0', alignItems: 'center', width: '100%', mt: 10 }}>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={() => navigate(-1)}>
                        <ArrowLeftIcon style={{ width: '15px' }} /> Back
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: '700' }}>Booking Details</Typography>
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box key={booking?.id} width="100%">
                        <CardContent
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: '1px solid #E4E4E4',
                                backgroundColor: '#F7F7F7',
                                borderRadius: '5px',
                            }}
                        >
                            <Box>
                                <Typography variant="h5" fontWeight={600} fontSize={22}>{booking?.title}</Typography>
                                <Typography sx={{ color: '#BCBCBC', fontSize: '14px' }}>
                                    {booking?.date} | {booking?.time}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                <Button variant="outlined"
                                    sx={{ border: '1px solid black', borderRadius: '20px', fontWeight: '700', fontSize: '12px', color: 'black', width: '100px' }} onClick={handleHelpClick}
                                >
                                    Help
                                </Button>
                                <Button variant="outlined"
                                    sx={{ borderRadius: '20px', fontWeight: '700', fontSize: '12px', backgroundColor: '#FF0000', color: 'white', width: '100px' }}
                                >
                                    SOS Call
                                </Button>
                            </Box>
                        </CardContent>

                        {/* {booking.maidDetails.map((maid) => ( */}
                        <Box key={booking?.id} mt={2} sx={{ margin: '25px 0', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'space-between' }}>
                            <Box>
                                <Box sx={{ width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Box sx={{ border: '1px solid #E4E4E4', borderRadius: '5px' }}>
                                        <Box sx={{ padding: '18px' }}>
                                            <Typography variant="h6" color="initial" pb={2} sx={{ fontWeight: '800', fontSize: '18px' }}>Maid Details</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '3rem' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                                    <img src={booking?.image} height={70} width={70} style={{ borderRadius: '10px' }} />
                                                    <Box >
                                                        <Typography variant="h5" sx={{ fontWeight: '800', fontSize: '18px' }}>{booking?.workerDetails?.firstName} {booking?.workerDetails?.lastName}</Typography>
                                                        <Typography variant="body2" sx={{ fontWeight: '600', lineHeight: '20px' }}>Gender : {booking?.workerDetails?.gender}</Typography>
                                                        <Typography variant="body2" sx={{ fontWeight: '600', lineHeight: '20px' }}>Language : {booking?.languages}</Typography>
                                                    </Box>
                                                </Box>
                                                <Button color="" sx={{ backgroundColor: '#01D74C', width: '30px', minWidth: '0px !important', height: '30px', color: 'white', borderRadius: '5px' }}> <CallIcon /> </Button>
                                            </Box>
                                            <Divider sx={{ padding: '10px 0' }} />
                                            <Box>
                                                <Typography variant="body2" color="initial" pb={2} sx={{ color: 'red', textAlign: 'center', paddingTop: '20px', paddingBottom: '0' }}>Free cancellation/reschedule before 1 hour.</Typography>
                                            </Box>
                                        </Box>

                                    </Box>

                                    <Box >
                                        <Typography variant="h6" color="initial" sx={{ fontWeight: '800', fontSize: '18px', padding: '30px 0 5px 0 ' }}>Cancellation Policy</Typography>
                                        <Typography variant="body2" color="initial">Free cancellations if done more than 3 hrs before the service or if a professional isn’t assigned. A fee will be charged otherwise.</Typography>
                                        <a href='' style={{ color: '#7E60BF', fontWeight: '700' }} >Read More</a>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '49%',
                                    border: '1px solid #E4E4E4',
                                    borderRadius: '5px',
                                    maxHeight: 'calc(90vh - 120px)',
                                    overflowY: 'auto',
                                    '&::-webkit-scrollbar': { display: 'none' },
                                }}
                            >
                                <Box sx={{ padding: '18px' }}>
                                    <Typography variant="h6" color="initial" pb={1} sx={{ fontWeight: '800', fontSize: '18px' }} >
                                        Booking Details
                                    </Typography>
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '20px', gap: '3.5rem' }}>
                                            <Button variant="outlined" sx={{
                                                border: '1px solid black', color: 'black', borderRadius: '20px', fontWeight: '700',
                                                fontSize: '12px !important', marginLeft: '34px',
                                            }}
                                                // disabled={maid?.status === 'inprogress' || maid?.status === 'cancelled'}
                                                onClick={handleRescheduleClick}
                                            >
                                                Reschedule
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    border: '1px solid #7E60BF',
                                                    borderRadius: '20px',
                                                    fontWeight: '700',
                                                    fontSize: '12px !important',
                                                }}
                                                // disabled={maid?.status === 'inprogress' || maid?.status === 'cancelled'}
                                                onClick={handleCancelBookingClick}
                                            >
                                                Cancel Booking
                                            </Button>
                                        </Box>
                                        <Divider />
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '20px 0',
                                            }}
                                        >
                                            <LocationOnIcon sx={{ fontSize: '20px' }} /> {booking?.bookingDetail?.serviceAddress1} ,{booking?.bookingDetail?.serviceAddress2} ,{booking?.bookingDetail?.serviceCity},{booking?.bookingDetail?.serviceState},{booking?.bookingDetail?.servicePincode}
                                        </Typography>
                                        <Divider />
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '20px 0',
                                            }}
                                        >
                                            <TimerIcon sx={{ fontSize: '20px' }} /> {booking?.slotTimeType}
                                        </Typography>
                                        <Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '20px', gap: '3.5rem' }}>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        border: '1px solid black',
                                                        color: 'black',
                                                        borderRadius: '20px',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important',
                                                        marginLeft: '34px',
                                                    }}
                                                    // disabled={maid?.status === 'inprogress' || maid?.status === 'cancelled'}
                                                    onClick={handleRescheduleClick}
                                                >
                                                    Reschedule
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        border: '1px solid #7E60BF',
                                                        borderRadius: '20px',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important',
                                                    }}
                                                    // disabled={maid?.status === 'inprogress' || maid?.status === 'cancelled'}
                                                    onClick={handleCancelBookingClick}
                                                >
                                                    Cancel Booking
                                                </Button>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '15px',
                                                            padding: '20px 0 5px 0',
                                                        }}
                                                    >
                                                        <img src={moneyBadge} height={20} width={20} />
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{ fontWeight: '600', display: 'flex', alignItems: 'center', gap: '15px' }}
                                                        >
                                                            ₹{booking?.netAmount}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px'
                                                        // padding: '20px 0 5px 0',
                                                    }}>
                                                        <Typography variant="" color="initial" sx={{ color: '#0DC07B', fontWeight: '900', paddingLeft: '2.3rem' }}>✓</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{ color: '#0DC07B', fontWeight: '700' }}
                                                        >
                                                            Paid Online
                                                        </Typography>
                                                    </Box>
                                                </Box>


                                                <a
                                                    style={{ color: '#7E60BF', fontWeight: '700', textDecoration: 'none', cursor: "pointer" }}
                                                    onClick={toggleBreakup}
                                                >
                                                    {showBreakup ? "Hide " : "View Breakup"}
                                                </a>
                                            </Box>

                                            {showBreakup && (
                                                <Box
                                                    sx={{
                                                        border: '1px solid #E4E4E4',
                                                        p: 2,
                                                        borderRadius: '10px',
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                                        Payment Breakdown
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>Item's Total</Typography>
                                                        <Typography variant="body2">{booking?.netAmount}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>Delivery Tip</Typography>
                                                        <Typography variant="body2">₹20</Typography>
                                                    </Box>
                                                    <hr />
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>
                                                            Platform fee
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <Typography variant="subtitle2">Platform fee</Typography>
                                                                        <Typography variant="body2" sx={{ color: '#ccc' }}>
                                                                            This fee helps us operate and improve our platform, delivering a seamless app experience.
                                                                        </Typography>
                                                                    </>
                                                                }
                                                                placement="top"
                                                            >
                                                                <IconButton size="small" style={{ marginLeft: 4 }}>
                                                                    <Info style={{ height: '13px', width: '15px' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                        <Box display="flex" alignItems="center">
                                                            <Typography
                                                                variant="body1"
                                                                color="text.secondary"
                                                                sx={{ textDecoration: 'line-through', marginRight: '8px', fontSize: '12px' }}
                                                            >
                                                                ₹7.00
                                                            </Typography>
                                                            <Typography variant="body2" color="text.primary">
                                                                ₹3.00
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>visitingCharges</Typography>
                                                        <Typography variant="body2">{booking?.visitingCharges}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>workerServiceChargeAmount</Typography>
                                                        <Typography variant="body2">{booking?.visitingCharges}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>Extra Discount</Typography>
                                                        <Typography variant="body2">₹20</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{ fontSize: '13px' }}>GST & Service Charges</Typography>
                                                        <Typography variant="body2">₹ {booking?.customerTaxAmount}</Typography>
                                                    </Box>
                                                </Box>
                                            )}

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* ))} */}
                    </Box>
                </Box>

                {isRescheduleOpen && <ReschedulePopup open={isRescheduleOpen} onClose={handleCloseReschedule} />}
                {isCancellationOpen && <BookingCancellation onClose={handleCloseCancellation} bookingId={id}/>}
            </Container>

        </>
        );

};

export default BookingDetails;
