import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/Context';

const ProtectedRoute = ({ children }) => {
    const { setOpenLogin, userToken } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userToken) {
            setOpenLogin(true);
            navigate('/', { replace: true });
        }
    }, [userToken, setOpenLogin, navigate]);

    return userToken ? children : null;
};

export default ProtectedRoute;
