import React from 'react';
import { Box, Typography } from '@mui/material';
import cleaners from '../../Assets/Images/cleaners.png';
import cleaning from '../../Assets/Images/cleaning.png';
import cooking from '../../Assets/Images/kitchen3.jpg';
import ironing from '../../Assets/Images/ironing.png';
import useResponsive from '../../hooks/useResponsive';


const Gallery = () => {
    const { isSmallScreen } = useResponsive();

    const images = [
        { src: cooking, alt: 'Cooking' },
        { src: cleaners, alt: 'Cleaners' },
        { src: cleaning, alt: 'Cleaning' },
        { src: ironing, alt: 'Ironing' },
    ];

    const scrollImages = [...images, ...images, ...images, ...images, ...images, ...images];

    return (
        <>
            <Box sx={{ marginTop: isSmallScreen ? "0px" : "45px", padding: isSmallScreen ? '25px' : 'auto' }}>
                <Box>
                    <Typography variant="h4" sx={{  marginLeft: isSmallScreen ? "0px" : "98px" }}>Gallery</Typography>
                </Box>
                <Box sx={{ display: 'flex', overflow: 'hidden', marginTop: '20px' }}>
                    <Box
                        sx={{ display: 'flex', animation: 'scroll 20s linear infinite', whiteSpace: 'nowrap', width: '200%', gap: '8px' }}  >
                        {scrollImages.map((image, index) => (
                            <Box
                                key={index}
                                sx={{ display: 'inline-block', minWidth: '350px' }}
                            >
                                <img
                                    style={{ height: '200px', width: '350px' }}
                                    src={image.src}
                                    alt={image.alt}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>

            <style>
                {`
                @keyframes scroll {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(-50%);
                    }
                }
                `}
            </style>
        </>
    );
};

export default Gallery;
