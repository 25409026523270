import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import { X } from "lucide-react";
import { Context } from "../context/Context";
import axios from "axios";
import apiUrl from "../config";

const AddressPopup = ({ open, onClose, address }) => {

  const { getUserDetailsByToken, userToken, getStates, states } = useContext(Context);
  const { isSmallScreen, isMediumScreen } = useResponsive();

  const [formData, setFormData] = useState({
    building: "",
    flatNumber: "",
    street: "",
    pincode: "",
    city: "",
    landmark: "",
    addressType: "",
    state: "",
    latAndLong: "",
  });

  const [errors, setErrors] = useState({
    building: "",
    flatNumber: "",
    street: "",
    pincode: "",
    city: "",
    landmark: "",
    addressType: "",
    state: "",
  });

  const addressTypes = [
    { id: 1, name: "Home" },
    { id: 2, name: "Office" },
  ];

  useEffect(() => {
    if (open && address) {
      setFormData({
        flatNumber: address.address1,
        street: address.address2,
        pincode: address.pincode,
        city: address.city,
        landmark: address.landMark || "",
        addressType: address.addressType_Id || "",
        state: address.state,
        latAndLong: address.latAndLong || "",
      });
    } else {
      setFormData({
        flatNumber: "",
        street: "",
        pincode: "",
        city: "",
        landmark: "",
        addressType: "",
        state: "",
        latAndLong: "",
      });
    }
  }, [open, address]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const latAndLong = `${latitude},${longitude}`;
          setFormData((prev) => ({ ...prev, latAndLong }));
        },
        (error) => {
          console.error("Error getting location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (open) {
      getCurrentLocation();
    }
  }, [open]);
  useEffect(() => {
    getStates()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors("");
  };

  const validatePincode = (pincode) => /^\d{6}$/.test(pincode);

  const validateForm = () => {
    let newErrors = {};

    const requiredFields = [
      "flatNumber",
      "street",
      "pincode",
      "city",
      "state",
      "addressType",
    ];

    requiredFields.forEach((field) => {
      const value = String(formData[field] || "").trim();

      if (!value) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      } else {
        if (field === "pincode" && !validatePincode(value)) {
          newErrors.pincode = "Invalid pincode. Must be 6 digits.";
        }
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).every((key) => !newErrors[key]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const dataToSubmit = [{
        ...(address?.id && { id: address.id }),
        addressType_Id: formData.addressType,
        address1: formData.flatNumber,
        address2: formData.street,
        city: formData.city,
        state: formData.state,
        pincode: formData.pincode,
        landMark: formData.landmark || "",
        latAndLong: formData.latAndLong || "",
        isDeleted: false,
      }];

      try {
        const res = address
          ? await axios.post(apiUrl + `users/updateuseraddressbytoken`, dataToSubmit, { headers: { Authorization: `Bearer ${userToken}` } })
          : await axios.post(apiUrl + "users/createuseraddressbytoken", dataToSubmit, { headers: { Authorization: `Bearer ${userToken}` } });

        if (res.data.status === true) {
          setFormData({
            building: "",
            flatNumber: "",
            street: "",
            pincode: "",
            city: "",
            landmark: "",
            addressType: "",
            state: "",
            latAndLong: "",
          });
          getUserDetailsByToken()
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "600px",
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "none",
    outline: "none",
    borderRadius: "6px",
  };

  return (
    <Box>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <Box sx={{ borderRadius: "10px", padding: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", lineHeight: isSmallScreen ? "40px" : "23.12px" }}>
                {address ? "Add Address" : "Edit Address"}
              </Typography>
              <X cursor="pointer" onClick={onClose} />
            </Box>
            <Box sx={{ borderRadius: 2, backgroundColor: "#fff" }} component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2} mt={2}>
                {/* <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Building name"
                    variant="outlined"
                    name="building"
                    value={formData.building}
                    onChange={handleChange}
                    error={!!errors.building}
                    helperText={errors.building}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Flat number"
                    variant="outlined"
                    name="flatNumber"
                    value={formData.flatNumber}
                    onChange={handleChange}
                    error={!!errors.flatNumber}
                    helperText={errors.flatNumber}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Street/Lane"
                    variant="outlined"
                    name="street"
                    value={formData.street}
                    onChange={handleChange}
                    error={!!errors.street}
                    helperText={errors.street}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="City"
                    variant="outlined"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Pincode"
                    variant="outlined"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small" error={!!errors.state}>
                    <InputLabel>State</InputLabel>
                    <Select
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      label="State"
                    >
                      {states && states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.stateName}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.state && <Typography variant="caption" color="error">{errors.state}</Typography>}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Select Address Type"
                    name="addressType"
                    value={formData.addressType}
                    onChange={handleChange}
                    error={!!errors.addressType}
                    helperText={errors.addressType}
                  >
                    {addressTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth size="small" label="Landmark (Optional)"
                    variant="outlined" name="landmark"
                    value={formData.landmark} onChange={handleChange}
                    error={!!errors.landmark}
                    helperText={errors.landmark}
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{
                    width: "150px",
                    backgroundColor: "#7E60BF",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#7E60BF",
                    },
                  }}
                >
                  {address && address.id ? "Update Address" : "Add Address"}
                </Button>

              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddressPopup;
