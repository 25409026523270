import React, { useState, useRef } from 'react';
import { Box, Typography, IconButton, Container, Grid, CardContent, Paper, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useResponsive from "../hooks/useResponsive"
import { HandHeart, Smile, Smartphone, UserCheck } from 'lucide-react';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

import meeting from '../Assets/Images/meeting.png';
import gruupdiscuss from '../Assets/Images/gruupdiscuss.png';
import { ArrowLeftIcon } from 'lucide-react';
import Footer from '../components/Footer';

// images

import babb from "../Assets/Images/girl.jpg"
import fan from "../Assets/Images/fan1.jpg"
import fire from "../Assets/Images/fire.jpg"

const AboutSevaki = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handlePlayButtonClick = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
        setPlaying(false);
      }
    }
  };
  return (
    <>

      <Container maxWidth="lg" >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }} ><Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={() => navigate(-1)} > <ArrowLeftIcon style={{ width: '15px' }} /> Back</Typography></Box>
        </Box>
        <Box sx={{ textAlign: 'center', color: '#fff', mt: 3 }}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#7E60BF' }}> About Sevaki </Typography>
          <Typography variant="body2" sx={{ marginTop: '1rem', maxWidth: '878px', lineHeight: 1.2, marginLeft: isSmallScreen ? '0px' : isMediumScreen ? '1rem' : '9rem', py: 3 }}>
            Sevaki is a leading online platform that provides reliable and professional maid services. Established with the
            vision of simplifying household management, Sevaki connects customers with skilled maids who are trained to handle various tasks,
            including cleaning, cooking, and caregiving. With a focus on quality and customer satisfaction, Sevaki ensures that all its maids
            go through a rigorous selection process, ensuring trust and safety. Whether you need daily assistance or occasional help,
            Sevaki offers flexible and customizable packages to meet your household needs. Join thousands of satisfied customers who have
            made Sevaki their go-to solution for hassle-free home management.
          </Typography>

        </Box>



        <Box sx={{ display: 'flex', justifyContent: 'center', color: 'primary.main', marginTop: "90px" }}>
          <Typography variant='h4' sx={{ fontWeight: 'bold' }}>Our Milestones</Typography>
        </Box>


        <Grid container justifyContent="space-evenly" marginTop={5} marginBottom={15}>
          <Grid item>
            <Paper
              elevation={0}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, backgroundColor: '#f0ebfb', borderRadius: '50%', width: '80px', height: '80px', }} >
              <HandHeart size={30} style={{ fontSize: "large", color: '#7E60BF', marginTop: '25px' }} />
            </Paper>
            <Box sx={{ textAlign: 'center', mt: 1 }}>
              <Typography variant="h6">3000+</Typography>
              <Typography variant="body2">Professionals</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Paper
              elevation={0}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, backgroundColor: '#f0ebfb', borderRadius: '50%', width: '80px', height: '80px', }} >
              <Smile size={30} style={{ color: '#7E60BF', marginTop: '25px' }} />
            </Paper>
            <Box sx={{ textAlign: 'center', mt: 1 }}>
              <Typography variant="h6">1M+</Typography>
              <Typography variant="body2">Happy Customer</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, backgroundColor: '#f0ebfb', borderRadius: '50%', width: '80px', height: '80px', }} >
              <Smartphone size={30} style={{ color: '#7E60BF', marginTop: '25px' }} />
            </Paper>
            <Box sx={{ textAlign: 'center', mt: 1 }}>
              <Typography variant="h6">1.2M+</Typography>
              <Typography variant="body2">App Downloads</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, backgroundColor: '#f0ebfb', borderRadius: '50%', width: '80px', height: '80px', }} >
              <UserCheck size={30} style={{ color: '#7E60BF', marginTop: '25px' }} />
            </Paper>
            <Box sx={{ textAlign: 'center', mt: 1 }}>
              <Typography variant="h6">1500+</Typography>
              <Typography variant="body2">Services</Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: isSmallScreen ? "100px" : isMediumScreen ? '7px' : 7, }}>
          <Grid container spacing={4} alignItems="center">
            {/* Left side: Text */}
            <Grid item xs={12} md={6}>
              <Typography variant="h4" color="primary" gutterBottom>
                Our Vision
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and scrambled it to
                make a type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially unchanged.
                It was popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </Typography>
            </Grid>

            {/* Right side: Image */}
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={gruupdiscuss} alt="Team meeting" sx={{ width: '100%', height: 'auto', borderRadius: 2, }} />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }} spacing={2} alignItems="center">
            {/* Left Side (Image Section) */}
            <Grid item xs={12} md={6}>
              <Box component="img" src={meeting} alt="Team meeting"
                sx={{ width: '100%', height: 'auto', borderRadius: 2, }} />
            </Grid>

            {/* Right Side (Text Section) */}
            <Grid item xs={12} md={6}>
              <CardContent>
                <Typography variant="h4" color="primary" gutterBottom>  Our Mission  </Typography>
                <Typography variant="body2" color="textSecondary">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
                  PageMaker including versions of Lorem Ipsum.
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Box>



        {/* <Box sx={{ backgroundColor: '#f4f4f4', borderRadius: '8px', mt: isSmallScreen ? 0 : isMediumScreen ? '7px' : 4, p: 2 }}>
          <Grid container sx={{ justifyContent: 'space-around', alignItems: "center", height: isSmallScreen ? "27vh" : isMediumScreen ? '15vh' : '17vh', }}>
            <Grid item >
              <Box
                component="img"
                src={Google}
                alt="Google"
                sx={{ objectFit: 'contain', height: "65px", width: '145px' }}
              />
            </Grid>
            <Grid item >
              <Box
                component="img"
                src={Facebook}
                alt="Facebook"
                sx={{ width: '100%', objectFit: 'contain', height: "65px", width: '145px' }}
              />
            </Grid>

            <Grid >
              <Box
                component="img"
                src={Oracle}
                alt="Oracle"
                sx={{ width: '100%', objectFit: 'contain', height: "65px", width: '145px' }}
              />
            </Grid>
            <Grid >
              <Box component="img" src={Abilio} alt="Abilio" sx={{ width: '100%', objectFit: 'contain', height: "65px", width: '145px' }} />
            </Grid>
            <Grid >
              <Box
                component="img"
                src={cdx}// Replace with actual logo
                alt="CD&V"
                sx={{ width: '100%', objectFit: 'contain', height: "65px", width: '145px' }}
              />
            </Grid>
          </Grid>
        </Box> */}

        <Box sx={{ display: 'flex', justifyContent: 'center', color: 'primary.main', mt: isSmallScreen ? 0 : isMediumScreen ? 3 : 15, mb: 7 }}>
          <Typography variant='h4' sx={{ fontWeight: 'bold' }}>Our Board Members</Typography>
        </Box>


        <Box>
          <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? "space-between" : isMediumScreen ? "space-between" : "space-evenly", alignItems: 'center', padding: '20px' }}  >
            {/* Card 1 */}
            <Box sx={{ textAlign: "center" }}>
              <Box component="img" src={fire} alt="description" sx={{ width: isSmallScreen ? 100 : isMediumScreen ? 125 : 200, height: isSmallScreen ? 100 : isMediumScreen ? 125 : 200, backgroundColor: 'lightgrey', borderRadius: '8px', mt: isSmallScreen ? "20px" : isMediumScreen ? "0px" : "0px", objectFit: 'cover', }} />              <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px' }}> Akhil Akula </Typography>
              <Typography variant="body2" sx={{ width: isSmallScreen ? 100 : isMediumScreen ? 125 : 195 }}>CEO & Managing Director</Typography>
            </Box>

            {/* Card 2 */}
            <Box sx={{ textAlign: "center" }}>
              <Box component="img" src={fan} alt="description" sx={{ width: isSmallScreen ? 100 : isMediumScreen ? 125 : 200, height: isSmallScreen ? 100 : isMediumScreen ? 125 : 200, backgroundColor: 'lightgrey', borderRadius: '8px', mt: isSmallScreen ? "20px" : isMediumScreen ? "0px" : "0px", objectFit: 'cover', }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px' }}> Suresh Peyyala</Typography>
              <Typography variant="body2">Chief Advisor</Typography>
            </Box>

            {/* Card 3 */}
            <Box sx={{ textAlign: "center" }}>
              <Box component="img" src={babb} alt="description" sx={{ width: isSmallScreen ? 100 : isMediumScreen ? 125 : 200, height: isSmallScreen ? 100 : isMediumScreen ? 125 : 200, backgroundColor: 'lightgrey', borderRadius: '8px', mt: isSmallScreen ? "20px" : isMediumScreen ? "0px" : "0px", objectFit: 'cover', }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '10px' }}> Raghav A </Typography>
              <Typography variant="body2">Lead Developer</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{ width: '800px', height: '350px', position: 'relative', borderRadius: '8px', overflow: 'hidden', cursor: 'pointer' }}>
            <video
              ref={videoRef}
              style={{ objectFit: 'cover', width: '100%' }}
              controls={false}
              onClick={handleVideoClick}>
              <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!playing && (
              <IconButton
                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'primary.main', fontSize: '48px', zIndex: 2 }}
                onClick={handlePlayButtonClick} >
                <PlayCircleFilledWhiteIcon fontSize="inherit" style={{ height: '40px' }} />
              </IconButton>
            )}
          </Box>
        </Box>


      </Container>
      <Footer /> </>
  );
};

export default AboutSevaki;
