// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   TextField,
//   Button,
//   Typography,
//   Modal,
//   Backdrop,
//   Fade,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemText,
// } from '@mui/material';
// import { X } from 'lucide-react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// const GOOGLE_MAPS_API_KEY = 'AIzaSyBwWB3Wca2DvrVbZLFjxwJ9HGyTd43hBFQ';

// const LocationPopup = ({ open, onClose }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [suggestions, setSuggestions] = useState([]);
//   const [mapCenter, setMapCenter] = useState({ lat: 20.5937, lng: 78.9629 }); // Default: India
//   const [selectedLocation, setSelectedLocation] = useState(null);
//   const [address, setAddress] = useState('');
//   const [isValidAddress, setIsValidAddress] = useState(false);

//   useEffect(() => {
//     if (searchTerm) {
//       fetchAutocompleteSuggestions(searchTerm);
//     } else {
//       setSuggestions([]);
//     }
//   }, [searchTerm]);

//   const fetchAutocompleteSuggestions = async (input) => {
//     if (isPlusCode(input)) {
//       // If input is a Plus Code, use Geocoding API to resolve it
//       fetchGeocodeFromPlusCode(input);
//       return;
//     }

//     const autocompleteUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(input)}&types=geocode&components=country:IN&key=${GOOGLE_MAPS_API_KEY}`;

//     const response = await fetch(autocompleteUrl);
//     const data = await response.json();

//     if (data.status === 'OK') {
//       setSuggestions(data.predictions);
//     } else {
//       setSuggestions([]);
//     }
//   };

//   const isPlusCode = (input) => {
//     const plusCodeRegex = /^[A-Z2-7]{4,7}\+?[0-9A-HJ-NP-Z]{1,7}$/;
//     return plusCodeRegex.test(input.trim());
//   };

//   const fetchGeocodeFromPlusCode = async (plusCode) => {
//     const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
//       plusCode
//     )}&key=${GOOGLE_MAPS_API_KEY}`;
//     const response = await fetch(geocodeUrl);
//     const data = await response.json();

//     if (data.status === 'OK' && data.results.length > 0) {
//       const { lat, lng } = data.results[0].geometry.location;
//       setMapCenter({ lat, lng });
//       setSelectedLocation({ lat, lng });
//       setAddress(data.results[0].formatted_address);
//       setIsValidAddress(true);
//       setSuggestions([]);
//     } else {
//       setIsValidAddress(false);
//       setSuggestions([]);
//     }
//   };

//   const handleSuggestionSelect = async (placeId) => {
//     const placeDetailsUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${GOOGLE_MAPS_API_KEY}`;
//     const response = await fetch(placeDetailsUrl);
//     const data = await response.json();

//     if (data.status === 'OK') {
//       const { lat, lng } = data.result.geometry.location;
//       setMapCenter({ lat, lng });
//       setSelectedLocation({ lat, lng });
//       setAddress(data.result.formatted_address);
//       setIsValidAddress(true);
//       setSuggestions([]);
//     }
//   };

//   const handleClearInput = () => {
//     setSearchTerm('');
//     setSuggestions([]);
//     setMapCenter({ lat: 20.5937, lng: 78.9629 });
//     setSelectedLocation(null);
//     setAddress('');
//     setIsValidAddress(false);
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       closeAfterTransition
//       BackdropComponent={Backdrop}
//       BackdropProps={{
//         timeout: 500,
//       }}
//     >
//       <Fade in={open}>
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: 600,
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             borderRadius: '10px',
//             outline: 'none',
//           }}
//         >
//           <TextField
//             label="Search for location"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             fullWidth
//             variant="outlined"
//             style={{ marginBottom: '10px' }}
//             InputProps={{
//               endAdornment: searchTerm && (
//                 <X
//                   onClick={handleClearInput}
//                   style={{
//                     cursor: 'pointer',
//                     color: '#888',
//                     padding: '5px',
//                   }}
//                   size={20}
//                 />
//               ),
//             }}
//           />

//           {/* Suggestions List */}
//           {suggestions.length > 0 && (
//             <List
//               sx={{
//                 maxHeight: 200,
//                 overflow: 'auto',
//                 border: '1px solid #ddd',
//                 borderRadius: '4px',
//                 marginTop: '10px',
//               }}
//             >
//               {suggestions.map((suggestion) => (
//                 <ListItem
//                   key={suggestion.place_id}
//                   disablePadding
//                   onClick={() => handleSuggestionSelect(suggestion.place_id)}
//                 >
//                   <ListItemButton>
//                     <ListItemText primary={suggestion.description} />
//                   </ListItemButton>
//                 </ListItem>
//               ))}
//             </List>
//           )}

//           <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
//             <GoogleMap
//               mapContainerStyle={{ height: '400px', width: '100%' }}
//               center={mapCenter}
//               zoom={15}
//             >
//               {selectedLocation && <Marker position={selectedLocation} />}
//             </GoogleMap>
//           </LoadScript>

//           {address && (
//             <Box sx={{ marginTop: '10px' }}>
//               <Typography variant="h6">Selected Address:</Typography>
//               <Typography variant="body1">{address}</Typography>
//             </Box>
//           )}

//           <Box sx={{ marginTop: '10px' }}>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => alert(`Selected address: ${address}`)}
//             >
//               Confirm Address
//             </Button>
//           </Box>
//         </Box>
//       </Fade>
//     </Modal>
//   );
// };

// export default LocationPopup;







// import React, { useState, useRef, useEffect } from 'react';
// import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
// import { TextField, Box, Button, Typography, Dialog, DialogContent } from '@mui/material';
// import { MapPin, X } from 'lucide-react';

// const GOOGLE_MAPS_API_KEY = 'AIzaSyBwWB3Wca2DvrVbZLFjxwJ9HGyTd43hBFQ';

// const LocationPopup = ({ open, onClose }) => {
//     const [searchTerm, setSearchTerm] = useState('');
//     const [mapCenter, setMapCenter] = useState({ lat: 20.5937, lng: 78.9629 }); // Default to India center
//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const [address, setAddress] = useState('');
//     const [isValidAddress, setIsValidAddress] = useState(false); // Track if the address is valid
//     const mapRef = useRef();

//     // Get user's current location
//     const getCurrentLocation = () => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     const { latitude, longitude } = position.coords;
//                     setMapCenter({ lat: latitude, lng: longitude });
//                 },
//                 (error) => {
//                     console.error('Error getting location:', error);
//                     setMapCenter({ lat: 20.5937, lng: 78.9629 }); // Fallback to default India center
//                 }
//             );
//         } else {
//             console.log('Geolocation is not supported by this browser.');
//             setMapCenter({ lat: 20.5937, lng: 78.9629 }); // Fallback to India center
//         }
//     };

//     // Call getCurrentLocation when the component mounts
//     useEffect(() => {
//         getCurrentLocation();
//     }, []);

//     const handleSearchChange = async (event) => {
//         const value = event.target.value;
//         setSearchTerm(value);

//         if (value.trim() === '') {
//             setMapCenter({ lat: 20.5937, lng: 78.9629 }); // Reset to India center if empty
//             setIsValidAddress(false); // Reset valid address flag
//             return;
//         }

//         const geocodeResults = await getGeocodeResults(value);
//         if (geocodeResults.length > 0) {
//             const { lat, lng } = geocodeResults[0].geometry.location; // Access lat and lng directly
//             setMapCenter({ lat, lng }); // Set the map center to the geocoded address
//             setSelectedLocation({ lat, lng }); // Move marker to the new location
//             setAddress(geocodeResults[0].formatted_address);
//             setIsValidAddress(true); // Mark address as valid
//         } else {
//             setIsValidAddress(false); // Address not valid
//         }
//     };

//     const getGeocodeResults = async (searchTerm) => {
//         const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
//             searchTerm
//         )}&components=country:IN&key=${GOOGLE_MAPS_API_KEY}`;
//         const response = await fetch(geocodeUrl);
//         const data = await response.json();
//         return data.results;
//     };

//     const handleMapClick = async (e) => {
//         const lat = e.latLng.lat();
//         const lng = e.latLng.lng();
//         const geocodeResults = await getGeocodeResultsByCoords(lat, lng);
//         if (geocodeResults.length > 0) {
//             setSelectedLocation({ lat, lng });
//             setAddress(geocodeResults[0].formatted_address);
//         }
//     };

//     const getGeocodeResultsByCoords = async (lat, lng) => {
//         const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`;
//         const response = await fetch(geocodeUrl);
//         const data = await response.json();
//         return data.results;
//     };

//     const handleClearInput = () => {
//         setSearchTerm('');
//         setMapCenter({ lat: 20.5937, lng: 78.9629 }); // Reset to India center
//         setSelectedLocation(null);
//         setAddress('');
//         setIsValidAddress(false); // Reset valid address flag
//     };

//     return (
//         <Dialog
//             open={open}
//             onClose={onClose}
//             maxWidth="sm"
//             sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 '& .MuiDialog-paper': {
//                     margin: 0,
//                     width: '500px',
//                     padding: '10px',
//                     boxSizing: 'border-box',
//                     borderRadius: '10px'
//                 }
//             }}
//         >
//             <DialogContent>
//                 <Box>
//                     <TextField
//                         label="Search for location"
//                         value={searchTerm}
//                         onChange={handleSearchChange}
//                         fullWidth
//                         variant="outlined"
//                         style={{ marginBottom: '10px' }}
//                         InputProps={{
//                             endAdornment: searchTerm && (
//                                 <X
//                                     onClick={handleClearInput}
//                                     style={{
//                                         cursor: 'pointer',
//                                         color: '#888',
//                                         padding: '5px',
//                                     }}
//                                     size={20}
//                                 />
//                             ),
//                         }}
//                     />

//                     {/* Conditionally render the map based on whether the address is valid */}
//                     {isValidAddress && (
//                         <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
//                             <GoogleMap
//                                 mapContainerStyle={{ height: '500px', width: '100%' }}
//                                 center={mapCenter}
//                                 zoom={15} // Zoom in closer to the searched location
//                                 onClick={handleMapClick}
//                                 ref={mapRef}
//                             >
//                                 {selectedLocation && (
//                                     <Marker
//                                         position={selectedLocation}
//                                         draggable
//                                         onDragEnd={(e) => handleMapClick(e)}
//                                     />
//                                 )}

//                                 {selectedLocation && (
//                                     <InfoWindow
//                                         position={selectedLocation}
//                                         onCloseClick={() => setSelectedLocation(null)}
//                                     >
//                                         <Typography variant="body2">{address}</Typography>
//                                     </InfoWindow>
//                                 )}
//                             </GoogleMap>
//                         </LoadScript>
//                     )}

//                     {address && (
//                         <Box sx={{ marginTop: '10px' }}>
//                             <Typography variant="h6">Selected Address:</Typography>
//                             <Typography variant="body1">{address}</Typography>
//                         </Box>
//                     )}

//                     <Box sx={{ marginTop: '10px' }}>
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             onClick={() => alert(`Selected address: ${address}`)}
//                         >
//                             Confirm Address
//                         </Button>
//                     </Box>
//                 </Box>
//             </DialogContent>
//         </Dialog>
//     );
// };

// export default LocationPopup;









// import React, { useState } from 'react';
// import { Box, List, ListItem, ListItemText, Typography, Divider, Modal, Backdrop, Fade } from '@mui/material';
// import { MapPin, X, LocateFixed } from 'lucide-react';

// const locations = [
//     {
//         name: 'Madhapur',
//         address: 'Hyderabad, Telangana, India'
//     },
//     {
//         name: 'Madhapur Metro Station',
//         address: 'Road number 36, Aditya Empire, Venkatagiri, Madhapur, Hyderabad, Telangana, India'
//     },
//     {
//         name: 'Madhapur Ayyappa Society',
//         address: 'Mega Hills, Madhapur, Hyderabad, Telangana, India'
//     }
// ];

// const LocationPopup = ({ open, onClose }) => {
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredLocations, setFilteredLocations] = useState([]);
//     const [currentLocation, setCurrentLocation] = useState(null);

//     const handleInputChange = (event) => {
//         const value = event.target.value;
//         setSearchTerm(value);

//         // Filter locations based on the input value
//         const filtered = locations.filter(location =>
//             location.name.toLowerCase().includes(value.toLowerCase())
//         );
//         setFilteredLocations(filtered);
//     };

//     const clearInput = () => {
//         setSearchTerm('');
//         setFilteredLocations([]);
//     };

//     const handleUseCurrentLocation = () => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     const { latitude, longitude } = position.coords;
//                     setCurrentLocation({ latitude, longitude });
//                     alert(`Current Location: Latitude: ${latitude}, Longitude: ${longitude}`);
//                 },
//                 (error) => {
//                     alert(`Error obtaining location: ${error.message}`);
//                 }
//             );
//         } else {
//             alert("Geolocation is not supported by this browser.");
//         }
//     };

//     return (
//         <Modal
//             open={open}
//             onClose={onClose}
//             closeAfterTransition
//             BackdropComponent={Backdrop}
//             BackdropProps={{
//                 timeout: 500,
//             }}
//         >
//             <Fade in={open}>
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         width: 500,
//                         bgcolor: 'background.paper',
//                         boxShadow: 24,
//                         p: 4,
//                         borderRadius: '10px',
//                         outline: 'none',
//                     }}
//                 >
//                     <Box sx={{ width: '100%' }}>
//                         <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
//                             <MapPin size={17} style={{ position: 'absolute', left: 10, color: '#000' }} />
//                             <input
//                                 type="text"
//                                 value={searchTerm}
//                                 onChange={handleInputChange}
//                                 placeholder="Enter your location"
//                                 aria-label="Search location"
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px 40px',
//                                     border: '1px solid #ccc',
//                                     borderRadius: '5px',
//                                     fontSize: '16px',
//                                     outline: 'none'
//                                 }}
//                             />
//                             {searchTerm && (
//                                 <X
//                                     onClick={clearInput}
//                                     style={{
//                                         position: 'absolute',
//                                         right: 10,
//                                         cursor: 'pointer',
//                                         color: '#888'
//                                     }}
//                                     size={20}
//                                     aria-label="Clear search"
//                                 />
//                             )}
//                         </div>
//                         <Typography
//                             onClick={handleUseCurrentLocation}
//                             sx={{
//                                 display: "flex",
//                                 alignItems: "center",
//                                 color: "#7E60BF",
//                                 padding: "20px 10px 0px 0px",
//                                 cursor: 'pointer',
//                             }}
//                             variant='h6'
//                         >
//                             <LocateFixed size={18} style={{ color: "#7E60BF", paddingRight: '8px' }} />
//                             Use current location
//                         </Typography>

//                         <List sx={{ marginTop: 2, borderTop: "4px solid #EBEBEB" }}>
//                             {filteredLocations.length > 0 ? (
//                                 filteredLocations.map((location, index) => (
//                                     <Box key={location.name}>
//                                         <ListItem
//                                             sx={{
//                                                 display: 'flex',
//                                                 alignItems: 'center',
//                                                 gap: '1rem',
//                                                 padding: '8px 0'
//                                             }}
//                                         >
//                                             <MapPin
//                                                 style={{
//                                                     backgroundColor: '#EBEBEB',
//                                                     padding: '8px',
//                                                     borderRadius: '50%',
//                                                     width: '18px',
//                                                     height: '18px'
//                                                 }}
//                                             />
//                                             <ListItemText
//                                                 primary={location.name}
//                                                 secondary={location.address}
//                                                 sx={{ overflow: 'hidden', paddingRight: '20px' }}
//                                             />
//                                         </ListItem>
//                                         {/* Only show Divider if it's not the last item */}
//                                         {index < filteredLocations.length - 1 && <Divider sx={{ height: "2px" }} />}
//                                     </Box>
//                                 ))
//                             ) : (
//                                 <Box sx={{ padding: '8px 0' }}>
//                                     {/* <Typography variant="body2" sx={{ color: '#888' }}>No locations found.</Typography> */}
//                                 </Box>
//                             )}
//                         </List>
//                     </Box>
//                 </Box>
//             </Fade>
//         </Modal>
//     );
// };

// export default LocationPopup;



import React, { useState } from 'react';
import { Box, Modal, Backdrop, Fade, TextField, List, ListItem, ListItemText, Divider, Typography, Button } from '@mui/material';
import { LocateFixed, MapPin, X } from 'lucide-react';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBwWB3Wca2DvrVbZLFjxwJ9HGyTd43hBFQ';

const LocationPopup = ({ open, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      setSuggestions([]);
      return;
    }

    const results = await getAutocompleteSuggestions(value);
    setSuggestions(results);
  };
  const handleUseCurrentLocation = async () => {
    setSearchTerm("")
    setSuggestions([])
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const geocodeUrl =` https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}&region=in&components`;
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.results && data.results.length > 0) {
              const currentLocation = {
                description: data.results[0].formatted_address,
                place_id: 'current_location', 
              };

              const isAlreadyAdded = suggestions.some(
                (suggestion) => suggestion.place_id === currentLocation.place_id
              );

              if (!isAlreadyAdded) {
                setSuggestions([currentLocation]); 
              }
            } else {
              alert('Unable to retrieve your location. Please try again.');
            }
          } catch (error) {
            console.error('Error fetching current location:', error);
            alert('Something went wrong while fetching your location.');
          }
        },
        (error) => {
          console.error('Geolocation error:', error);
          alert('Unable to access your location. Please check your browser settings.');
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };



  const getAutocompleteSuggestions = async (searchTerm) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(searchTerm)}&key=${GOOGLE_MAPS_API_KEY}`;
    const response = await fetch(geocodeUrl);
    const data = await response.json();
    return data.predictions.slice(0, 5); 
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion.description);
    setSelectedLocation(suggestion);
    setSuggestions([]);
  };

  const clearInput = () => {
    setSearchTerm('');
    setSuggestions([]);
    setSelectedLocation(null);
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition
      BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, }} >
      <Fade in={open}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
          outline: 'none',
        }}
        >

          <Box sx={{ width: '100%' }}>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <MapPin size={17} style={{ position: 'absolute', left: 10, color: '#000' }} />
              <input type="text" value={searchTerm} onChange={handleInputChange}
                placeholder="Enter your location"
                aria-label="Search location"
                style={{
                  width: '100%',
                  padding: '10px 40px',
                  border: '1px solid #ccc',
                  borderRadius: '5px', fontSize: '16px', outline: 'none',
                }}
              />
              {searchTerm && (
                <X onClick={clearInput}
                  style={{ position: 'absolute', right: 10, cursor: 'pointer', color: '#888', }} size={20} aria-label="Clear search"
                />
              )}
            </div>
            <Typography
              sx={{
                display: "flex", alignItems: "center", color: "#7E60BF", padding: "20px 10px 0px 0px", cursor: 'pointer',
              }} variant='h6'
              onClick={handleUseCurrentLocation}
            >

              <LocateFixed size={18} style={{ color: "#7E60BF", paddingRight: '8px' }} /> Use current location</Typography>

            {suggestions.length > 0 && (
              <List sx={{ marginTop: 2, borderTop: "4px solid #EBEBEB" }}>
                {suggestions.map((suggestion, index) => (
                  <Box key={suggestion.place_id}>
                    <ListItem sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '8px 0', cursor: 'pointer', }}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      <MapPin style={{
                        backgroundColor: '#EBEBEB', padding: '8px',
                        borderRadius: '50%', width: '20px', height: '20px', flexShrink: 0, flexGrow: 0,
                      }}
                      />

                      <ListItemText primary={suggestion.description} sx={{ overflow: 'hidden', paddingRight: '20px' }} />
                    </ListItem>
                    {index < suggestions.length - 1 && <Divider sx={{ height: "2px" }} />}
                  </Box>
                ))}
              </List>
            )}




          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LocationPopup;






