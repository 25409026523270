import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, Typography, Box, Container, Chip, CircularProgress } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import axios from "axios";
import DosaImage from "../../../Assets/Images/DosaImage.png";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import apiUrl from "../../../config";
import Navbar from "../../../components/Navbar";
import BottomNavBar from "../../../components/BottomNavbar";

const statusColors = {
  Accepted: "#0DC07B",
  Scheduled: "#FF8000",
  Cancelled: "#FF0000",
  InProgress: "yellow",
};

const MyBookings = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const navigate = useNavigate();
  const [bookingsData, setBookingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userToken, userDetails } = useContext(Context);

  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}booking/searchbookings`, {
          userId: userDetails.id,
          pageSize: 10,
          pageNumber: 1,
        });
        setBookingsData(response.data.bookings);
      } catch (err) {
        setError(err.response?.data.message || "Failed to fetch bookings data");
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userToken]);

  const handleBooking = (id) => {
    navigate(`/bookingdetails/${id}`);
  };

  // Helper function to format the date with the day suffix and month name
  const formatDate = (date) => {
    const day = date.getDate();
    const suffix = ["th", "st", "nd", "rd"][((day % 10) > 3 || (day % 100) > 10 && (day % 100) < 14) ? 0 : day % 10];
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" }); // "Tue"
    const month = date.toLocaleString("en-US", { month: "long" }); // "October"
    const year = date.getFullYear(); // 2024
    return `${dayOfWeek} ${day}${suffix}, ${month} ${year}`;
  };

  // Helper function to format the time as '2:00 PM'
  const formatTime = (date) => {
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  if (loading) {
    return (
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Navbar />
      <BottomNavBar />

      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 10 }}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                maxWidth: "620px",
                justifyContent: "space-between",
                margin: "20px 0 10px 0",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                color="initial"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowLeftIcon style={{ width: "15px" }} /> Back
              </Typography>
              <Typography variant="h5" color="initial" sx={{ fontWeight: "700" }}>
                My Bookings
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" alignItems="center" width="100%" justifyContent="space-between">
              {bookingsData?.map((booking) => {
                const actualStartDateTime =
                  booking.bookingSlot?.[0]?.actualStartDateTime && new Date(booking.bookingSlot[0].actualStartDateTime);
                const formattedDate = actualStartDateTime ? formatDate(actualStartDateTime) : "N/A";
                const formattedTime = actualStartDateTime ? formatTime(actualStartDateTime) : "N/A";

                return (
                  <Box key={booking.id} width={isSmallScreen ? "100%" : isMediumScreen ? "100%" : "49%"}>
                    <Card
                      style={{
                        margin: "10px 0",
                        display: "flex",
                        alignItems: "center",
                        boxShadow: "none",
                        border: "1px solid #E4E4E4",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleBooking(booking.bookingID)}
                    >
                      <CardContent
                        style={{
                          display: "flex",
                          flexDirection: isSmallScreen ? "column" : "",
                          alignItems: isSmallScreen ? "" : "center",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={booking.image || DosaImage}
                            alt={booking.foodType || "Booking image"}
                            style={{
                              width: "80px",
                              height: "80px",
                              backgroundColor: "#f0f0f0",
                              margin: "10px",
                            }}
                          />
                          <Box sx={{ marginLeft: "15px" }}>
                            <Typography variant="h5" fontWeight={600} fontSize={22}>
                              {booking.foodType}
                            </Typography>
                            <Typography color="textSecondary" sx={{ color: "black", fontSize: "14px" }}>
                              {formattedDate} | {formattedTime}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Chip
                            label={booking.bookingStatus}
                            style={{
                              backgroundColor: statusColors[booking.bookingStatus],
                              color: "white",
                              width: "110px",
                            }}
                          />
                          <ArrowRightIcon style={{ width: "20px" }} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default MyBookings;







// import React from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Container,
//   Chip,
//   Divider,
// } from "@mui/material";
// import useResponsive from "../../../hooks/useResponsive";

// import DosaImage from "../../../Assets/Images/DosaImage.png";
// import MealsImage from "../../../Assets/Images/MealsImage.png";
// import RiceImage from "../../../Assets/Images/RiceImage.png";

// import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
// import { useNavigate } from "react-router-dom";
// import { Padding } from "@mui/icons-material";

// const bookingsData = [
//   {
//     id: 1,
//     title: "Breakfast",
//     date: "Tue, July 21st, 2020",
//     time: "12:00 PM",
//     status: "Accepted",
//     image: DosaImage,
//     maidDetails: [
//       {
//         id: 1,
//         name: "Service 1",
//         phone_number: "1234567890",
//         status: "accepted",
//         image:
//           "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
//         maidLocation: "madhapur",
//         amount: 300,
//         address: "123 Elm Street, Springfield, IL",
//         slot: "Daily-sat, Aug31, 9:00AM",
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: "Combo's",
//     date: "Fri, July 17th, 2020",
//     time: "12:00 PM",
//     status: "Scheduled",
//     image: MealsImage,
//     maidDetails: [
//       {
//         id: 2,
//         name: "Service 2",
//         image:
//           "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
//         phone_number: "0987654321",
//         maidLocation: "madhapur",
//         amount: 200,
//         status: " not accepted",
//         address: "456 Maple Avenue, Springfield, IL",
//         slot: "Daily-sat, Sep7, 12:00PM",
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: "Lunch + Dinner",
//     date: "Sat, June 6th, 2020",
//     time: "1:00 PM",
//     status: "Cancelled",
//     image: RiceImage,
//     maidDetails: [
//       {
//         id: 1,
//         name: "Service 1",
//         phone_number: "1234567890",
//         status: "accepted",
//         image:
//           "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
//         maidLocation: "madhapur",
//         amount: 300,
//         address: "123 Elm Street, Springfield, IL",
//         slot: "Daily-sat, Aug31, 9:00AM",
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: "Dinner",
//     date: "Tue, July 24st, 2020",
//     time: "4:00 PM",
//     status: "InProgress",
//     image: DosaImage,
//     maidDetails: [
//       {
//         id: 4,
//         name: "Bhanu",
//         phone_number: "1234567890",
//         status: "inprogress",
//         image:
//           "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
//         maidLocation: "kukatpally",
//         amount: 450,
//         address: "123 Elm Street, Springfield, IL",
//         slot: "Daily-sat, Aug31, 9:00AM",
//       },
//     ],
//   },
// ];

// const statusColors = {
//   Accepted: "#0DC07B",
//   Scheduled: "#FF8000",
//   Cancelled: "#FF0000",
//   InProgress: "yellow",
// };

// const MyBookings = () => {
//   const { isSmallScreen, isMediumScreen } = useResponsive();

//   const navigate = useNavigate();

//   const handlebooking = (id) => {
//     navigate(`/bookingdetails/${id}`);
//   };

//   return (
//     <Container>
//       <>
//         <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
//           <Box sx={{ width: "100%" }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 maxWidth: "620px",
//                 justifyContent: "space-between",
//                 margin: "20px 0 10px 0",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <Typography
//                 variant="h6"
//                 color="initial"
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "8px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => navigate(-1)}
//               >
//                 {" "}
//                 <ArrowLeftIcon style={{width:'15px'}} /> Back
//               </Typography>
//               <Typography
//                 variant="h5"
//                 color="initial"
//                 sx={{ fontWeight: "700" }}
//               >
//                 My Bookings
//               </Typography>
//             </Box>
//             <Box
//               display="flex"
//               // flexDirection="column"
//               flexWrap='wrap'
//               alignItems="center"
//               width="100%"
//               justifyContent='space-between'
//             >
//               {bookingsData.map((booking) => (
//                 <Box
//                   key={booking.id}
//                   width={
//                     isSmallScreen ? "100%" : isMediumScreen ? "100%" : "49%"
//                   }
                  
//                 >
//                   <Card
//                     style={{
//                       margin: "10px 0",
//                       display: "flex",
//                       alignItems: "center",
//                       boxShadow: "none",
//                       border: "1px solid #E4E4E4",
//                       cursor: "pointer",
//                     }}
//                     onClick={() => handlebooking(booking.id)}
//                   >
//                     <CardContent
//                       style={{
//                         display: "flex",
//                         flexDirection: isSmallScreen ? "column" : "",
//                         alignItems: isSmallScreen ? "" : "center",
//                         justifyContent: "space-between",
//                         width: "100%",
//                         padding:'10px'
//                       }}
//                     >
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img
//                           src={booking.image}
//                           style={{
//                             width: "80px",
//                             height: "80px",
//                             backgroundColor: "#f0f0f0",
//                             margin: "10px",
//                           }}
//                         />
//                         <Box sx={{ marginLeft: "15px" }}>
//                           <Typography
//                             variant="h5"
//                             fontWeight={600}
//                             fontSize={22}
//                           >
//                             {booking.title}
//                           </Typography>
//                           <Typography
//                             color="textSecondary"
//                             sx={{ color: "#BCBCBC", fontSize: "14px" }}
//                           >
//                             {booking.date} | {booking.time}
//                           </Typography>
//                         </Box>
//                       </Box>
//                       <Box
//                         sx={{
//                           display: "flex",
//                           alignItems: "center",
//                           gap: "8px",
//                         }}
//                       >
//                         <Chip
//                           label={booking.status}
//                           style={{
//                             backgroundColor: statusColors[booking.status],
//                             color: "white",
//                             width: "110px",
//                           }}
//                         />
//                         <ArrowRightIcon style={{width:'20px'}} />
//                       </Box>
//                     </CardContent>
//                   </Card>
//                 </Box>
//               ))}
//             </Box>
//           </Box>
//         </Box>
//       </>
//     </Container>
//   );
// };

// export default MyBookings;