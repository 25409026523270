import React, { useState, useRef } from 'react';
import { Box, Typography, Modal, TextField, Button, Link, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '40px',
};

const MobileNumber = () => {
    const [open, setOpen] = useState(true);
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpSent, setOtpSent] = useState(false); // State to track OTP sent status
    const otpRefs = useRef([]);

    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        if (!/^[0-9]$/.test(value) && value !== "") return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            otpRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            otpRefs.current[index - 1].focus();
        }
    };

    const handleMobileChange = (e) => {
        const { value } = e.target;
        // Allow only numeric values and restrict to 10 digits
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };

    const handleSendOtp = () => {
        if (mobileNumber.length === 10) { // Assuming a valid mobile number is 10 digits
            setOtpSent(true);
            // Simulate sending OTP (this is where you'd call an API)
            console.log("OTP sent to", mobileNumber);
        } else {
            alert('Please enter a valid mobile number');
        }
    };

    const handleClose = () => setOpen(false); // Close the modal

    // Check if all OTP fields are filled
    const isOtpValid = otp.every(value => value !== "");

    return (
        <Box>
            <Typography variant="h5" sx={{ marginBottom: '20px' }}>Notifications</Typography> {/* Page Heading */}

            <Modal
                open={open}
                onClose={handleClose} // Close modal when clicking outside or pressing the escape key
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-title" variant="h5" component="h2">
                            Update mobile number
                        </Typography>
                        {/* Close Icon button with onClick event */}
                        <IconButton sx={{ position: 'relative', bottom: 40, left: 35 }} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Mobile Number Input */}
                    <TextField
                        label="Mobile Number"
                        value={mobileNumber}
                        size="small"
                        type="tel" // Changed from "number" to "tel"
                        onChange={handleMobileChange}
                        InputProps={{
                            readOnly: otpSent, // Disable input when OTP is sent
                        }}
                        style={{
                            width: '100%',
                            fontSize: '20px',
                            height: '30px',
                            margin: '30px 0 15px 0',
                            outline: 'none',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0px', // Set borderRadius to 0 to remove rounding
                            },
                        }}
                        inputProps={{
                            maxLength: 10, // Restrict to 10 digits
                        }}
                    />

                    {/* Send OTP Button */}
                    {!otpSent && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendOtp}
                                sx={{ width: '150px', borderRadius: '20px' }}
                            >
                                Send OTP
                            </Button>
                        </Box>
                    )}

                    {otpSent && (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0 30px 0' }}>
                                {otp.map((value, index) => (
                                    <TextField
                                        key={index}
                                        variant="outlined"
                                        inputProps={{ maxLength: 1, style: { textAlign: 'center' }, pattern: '[0-9]*' }}
                                        value={value}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        sx={{ width: '45px', height: '45px' }}
                                        inputRef={(el) => (otpRefs.current[index] = el)}
                                    />
                                ))}
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '10px' }}>
                                <Typography variant="" color="initial">Don't receive OTP?  </Typography>
                                <Link href="#" onClick={() => alert('OTP Resent')}>Resend</Link>
                            </Box>
                        </>
                    )}

                    {otpSent && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                                sx={{ width: '150px', borderRadius: '20px' }}
                                disabled={!isOtpValid} 
                            >
                                Update
                            </Button>
                        </Box>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default MobileNumber;
