import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

// Import your logo image
import logoImage from '../Assets/Images/sevakilogo2.svg'; // Ensure the path is correct

// Styled components for customizing the AppBar
const LogoImage = styled('img')({
  width: '80px', // Increase logo size
  height: '50px',
});

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));  // Check if screen is mobile

  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.palette.primary.contrastText, boxShadow: 'none' }}>
      <Toolbar>
        {/* Logo */}
        <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 2 }}>
          <LogoImage
            src={logoImage}
            alt="Sevaki Logo"
            sx={{ width: '160px', height: '50px', marginLeft: '85px' }}
          />
        </IconButton>

        {/* Title */}
        <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ flexGrow: 1, display: isMobile ? 'block' : 'flex' }}>
          Sevaki
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
