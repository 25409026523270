import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Container, Button, TextField, Grid, Card, Select, MenuItem, CardContent, Checkbox, FormControlLabel, Divider, InputLabel, ListItemText, } from "@mui/material";
import { ArrowLeft, BadgePercent, ChevronDown, ChevronRight, CircleCheck, Info, Minus, Pencil, Plus, ShieldCheck, X } from "lucide-react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import miadimg from "../Assets/Images/miad-img.png";
import Breakfastimg from "../Assets/Images/breackfast-img.png";
import dosepowder from "../Assets/Images/dosepowder.png";
import useResponsive from "../hooks/useResponsive";
import Bookingsuccessfull from "../popup/BookingPopUp/Bookingsuccessfull";
import { useNavigate } from "react-router-dom";
import AddressPopup from "../popup/AddressPopup";
import apiUrl from "../config";
import axios from "axios";
import Feilds from "../common/Feilds";

import Reschedulepopup from '../popup/Reschedulepopup';
import { Context } from "../context/Context";


const Checkoutpage = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(1 || 2);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [serviceFields, setServiceFields] = useState(null);
  
  const [addressOpen, setAddressOpen] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [openReschedulePopup, setOpenReschedulePopup] = useState(false);
  const { userAddress, dynamicValues, userDetails } = useContext(Context)
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null)
  const navigate = useNavigate()

  const handleOpenAddressPopUp = (address) => {
    setSelectedAddress(address);
    setAddressOpen(true);
  };
  const handleCloseAddressPopUp = () => { setAddressOpen(false); };
  const handleOpenReschedulePopup = () => { setOpenReschedulePopup(true); };
  const handleCloseReschedulePopup = () => { setOpenReschedulePopup(false); };
  const handleCloseSuccess = () => { setOpenSuccess(false); };

  const handleclickBack = () => {
    navigate("/maid")
  }
  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const toggleAccordion = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? 1 : accordionId);
  };

  const createBooking = async () => {
    const maidDetailsString = localStorage.getItem("maidDetails");
    const maidDetails = JSON.parse(maidDetailsString);
    try {
      const BookingResponse = await axios.post(apiUrl + "booking/createbooking", {
        userId: userDetails.id,
        workerUserId: maidDetails.workerUserId,
        serviceId: maidDetails.serviceId,
        bookingFrequencyType: maidDetails.bookingFrequencyType,
        subServiceId: maidDetails.subCategoryId,
        serviceAddress1: addressSelected.address1,
        serviceAddress2: addressSelected.address2,
        serviceCity: addressSelected.city,
        serviceState: addressSelected.state,
        servicePincode: addressSelected.pincode,
        serviceLandMark: addressSelected.landmark,
        serviceLatAndLong: addressSelected.latAndLong,
        bookingServiceCooking: [{
          ...dynamicValues,
          foodType: maidDetails.foodType,
          cuisineType: maidDetails.cuisineType,
          subServiceId: maidDetails.subCategoryId,
          isActive: true,
          isDeleted: false
        }],
        bookingSlot: [
          {
            slotTimeType: 0,
            isActive: true
          }
        ]
      });

      if (BookingResponse.data.status === true) {
        setOpenSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const storedServiceFieldsString = localStorage.getItem("serviceFields");
    if (storedServiceFieldsString) {
      const storedServiceFields = JSON.parse(storedServiceFieldsString);
      if (storedServiceFields) {
        setServiceFields([storedServiceFields]);
      } else if (Array.isArray(storedServiceFields) && storedServiceFields.length > 0 && storedServiceFields[0].title) {
        setServiceFields(storedServiceFields);
      } else {
        console.log("Unknown structure for storedServiceFields");
      }
    } else {
      console.log("No service fields found in local storage");
    }
  }, []);

  const getDropDownValues = async () => {
    if (!serviceFields || !serviceFields[0]) {
      return;
    }
    const maidDetails = JSON.parse(localStorage.getItem("maidDetails"));

    try {
      const response = await axios.get(`${apiUrl}servicefields/getservicefielddropdownsbysubserviceid?subServiceId=${maidDetails.subCategoryId}`);
      setDropdownValues(response.data.serviceFields);
    } catch (error) {
      console.error('Error fetching dropdown values:', error);
    }
  };

  useEffect(() => {
    if (serviceFields && serviceFields.length > 0) {
      getDropDownValues();
    }
  }, [serviceFields]);

  return (
    <Container>
      <>
        <Box sx={{ mt: '65px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mb: 2, cursor: "pointer", }} onClick={handleclickBack} >
                <ArrowLeft sx={{ fontSize: "small" }} size={15} />
                <Typography variant="h6">Back</Typography>
              </Box>

              <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px" }} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", }} onClick={() => toggleAccordion(1)}>
                  <Typography variant="h5">Address</Typography>
                  <ChevronDown style={{ transform: openAccordion === 1 ? "rotate(0deg)" : "rotate(-90deg)", transition: "transform 0.3s ease", }} />
                </Box>

                <Box
                  sx={{
                    maxHeight: openAccordion === 1 ? "auto" : "0px",
                    opacity: openAccordion === 1 ? 1 : 0,
                    overflow: "hidden",
                    height: userAddress?.length > 0 ? "45vh" : "auto",
                    transition: "max-height 0.2s ease, opacity 0.5s ease",
                    width: '100%',
                    overflow: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none'
                  }}
                >
                  {userAddress?.length > 0 ? (
                    <Box>
                      {userAddress?.map((address, index) => {
                        return (
                          <Card
                            key={index}
                            sx={{ mt: 4, display: "flex", alignItems: "center", border: "1px solid #E4E4E4", borderRadius: "10px", boxShadow: "none", }}>
                            <CardContent onClick={() => setAddressSelected(address)}>
                              {addressSelected?.id === address?.id ? (
                                <CircleCheck fill="#7E60BF" color="white" />
                              ) : (
                                <div style={{ width: '13px', height: '13px', borderRadius: '50%', border: '2px solid #E4E4E4', display: 'inline-block', }}
                                />
                              )}
                            </CardContent>
                            <CardContent>
                              <Typography variant="h6" sx={{ fontWeight: "bold" }}>Home</Typography>
                              <Typography variant="body1" sx={{ color: "#000000" }}>
                                {address.address1}, {address.address2}, {address.city}, {address.state}, {address.landmark} {address.pincode}
                              </Typography>
                              <Box sx={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer", }} onClick={() => handleOpenAddressPopUp(address)} >
                                <Pencil size={15} fill="#7E60BF" color="#7E60BF" />
                                <Typography variant="h6" sx={{ color: "primary.main" }}>Edit</Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        );
                      })}
                      <Typography
                        variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mt: 2, color: "primary.main", textDecorationLine: "underline", cursor: "pointer", }}
                        onClick={handleOpenAddressPopUp}
                      >Add Address</Typography>
                    </Box>
                  ) : (
                    <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mt: 2, color: "primary.main", textDecorationLine: "underline", cursor: "pointer", }} onClick={handleOpenAddressPopUp} >Add Address</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px", mt: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }} onClick={() => toggleAccordion(2)}>
                  <Typography variant="h5">Booking Form</Typography>
                  <ChevronDown style={{ transform: openAccordion === 2 ? "rotate(0deg)" : "rotate(-90deg)", transition: "transform 0.3s ease", }} />
                </Box>
                {openAccordion === 2 && (<Box sx={{ mt: 5 }}><Feilds fields={serviceFields[0].deepFields} dropdownValues={dropdownValues} /></Box>)}
              </Box>

              <Box mt={4}>
                <Box>
                  <Typography variant="h5">Cancellation policy</Typography>
                  <Typography variant="body2" sx={{ color: "#BCBCBC" }}>Free cancellations if done more than 3 hrs before theserviceor if a professional isn’t assigned. A fee will bechargedotherwise.</Typography>
                  <Typography
                    variant="h6" sx={{ fontWeight: "bold", cursor: "pointer", mt: 1, color: "primary.main", textDecorationLine: "underline", }} >Read More</Typography>
                </Box>
              </Box>
            </Grid>

            {/* Booking DEtails */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  marginTop: "10px",
                  height: isSmallScreen ? "auto" : isMediumScreen ? "auto" : "480px",
                  overflow: "scroll",
                  "&::-webkit-scrollbar": { display: "none" },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                }}
              >
                <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px 10px 0px 0px", }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, }}>
                    <Typography variant="h5">Booking Details</Typography>
                    <Typography variant="h6" color="primary" sx={{ fontWeight: "bold", textDecorationLine: "underline", cursor: "pointer", }} onClick={handleOpenReschedulePopup} >Update Slot</Typography>

                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "15px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                      <img src={miadimg} width="80px" height="80px" alt="not found" />
                      <Typography variant="h5">Rohit Reddy</Typography>
                      <ShieldCheck fill="#0DC07B" color="white" />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <img src={Breakfastimg} width="80px" height="80px" alt="not found" />
                        <Typography variant="h5">Breakfast</Typography>
                      </Box>

                      <Box><Typography variant="h5" fontWeight="bold" color="primary">₹399.00</Typography></Box>
                    </Box>
                  </Box>
                  <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px", }} mt={2}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <img src={dosepowder} width="60px" height="60px" alt="not found"
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="h6">Dosa Batter</Typography>
                          <Typography variant="body1" color="primary" fontWeight="bold">₹90.34</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: " 1px solid #7E60BF",
                            padding: "8px 10px",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                            gap: "10px",
                            backgroundColor: "#f0ebfb",
                            color: "primary.main",
                          }}
                        >
                          <Minus size={20} cursor="pointer" />
                          <Typography variant="body2" color="primary.main">1</Typography>
                          <Plus size={20} cursor="pointer" />
                        </Box>
                      </Box>
                    </Box>
                    <Divider></Divider>

                    <Box mt={2}>
                      <FormControl>
                        <RadioGroup sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
                          aria-labelledby="delivery-options-radio-buttons-group-label" name="delivery-options-group" defaultValue="withMaid"
                        >
                          <FormControlLabel value="beforeMaid" control={<Radio size="small" />} label="Should the product be delivered before Maid?"
                          />
                          <FormControlLabel value="withMaid" control={<Radio size="small" />} label="Arrival with Maid"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderTop: "none", borderRadius: "0px 0px 10px 10px", }}>
                  <Typography variant="h5" sx={{ mb: 1 }}>Payment Summary</Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Service Price</Typography>
                    <Typography variant="body2">₹399.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <Typography variant="body1" textAlign="center" fontSize="13px">Add-on</Typography>
                      <Info size={13} />
                    </Box>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Fees & Taxes</Typography>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Discount</Typography>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", color: "#E4E4E4", mt: 2, mb: 2, }}>
                    <Box sx={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#E4E4E4", }} />
                    <Divider sx={{ flexGrow: 1, borderColor: "#E4E4E4", borderWidth: 1, borderStyle: "solid", }} />
                    <Box sx={{ width: 8, height: 8, borderRadius: "50%", backgroundColor: "#E4E4E4", }} />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h5" fontSize="13px" sx={{ fontWeight: "bold", color: "primary.main" }}>Total Price</Typography>
                    <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>₹599.00</Typography>
                  </Box>
                </Box>

                <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px", mt: 2, }}>
                  <Typography variant="h5" sx={{ mb: 2 }}>Booking Slots</Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Cuisine</Typography>
                    <Typography variant="body2">South Indian</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Meal Type</Typography>
                    <Typography variant="body2">Veg & Non-Veg</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Slot Type</Typography>
                    <Typography variant="body2">Daily</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Morning Timing</Typography>
                    <Typography variant="body2">8:30 AM</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" fontSize="13px">Evening Timing</Typography>
                    <Typography variant="body2">6:30 PM</Typography>
                  </Box>
                </Box>

                <Box sx={{ border: "1px solid #E4E4E4", p: 2, mt: 2, borderRadius: "10px", }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", }}>
                      <BadgePercent size={20} fill="#0DC07B" color="#FFFFFF" />
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>Coupons & Offers</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "20px", }}>
                      <Typography variant="body2" color="primary">4 offers available</Typography>
                      <ChevronRight size={20} />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox size="small" checked={checkboxChecked} onChange={handleCheckboxChange} />

                  <Typography>I accept all Terms & Conditions and Privacy policy.</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px", }} >
                <Button variant="contained" sx={{
                    padding: "8px 24px", borderRadius: "30px",
                    backgroundColor: checkboxChecked ? "#7E62CC" : "transparent",
                    border: checkboxChecked ? "none" : "1px solid #E4E4E4",
                    color: checkboxChecked ? "#FFFFFF" : "black",
                    cursor: checkboxChecked ? "pointer" : "not-allowed",
                  }} onClick={createBooking} disabled={!checkboxChecked}
                >Proceed to Pay
                </Button>
              </Box>
            </Grid>

          </Grid>
        </Box>

        <AddressPopup open={addressOpen} onClose={handleCloseAddressPopUp} address={selectedAddress} />

      </>
      {openSuccess && (<Bookingsuccessfull open={openSuccess} onClose={handleCloseSuccess} />)}
      <Reschedulepopup open={openReschedulePopup} onClose={handleCloseReschedulePopup} />

    </Container >
  );
};

export default Checkoutpage;
