import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  IconButton,
  Box,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import dayjs from "dayjs";
import useResponsive from "../hooks/useResponsive";
import apiUrl from "../config";
import axios from "axios";

const FilterPopUp = () => {
  const { selectService, openFilterPopUp, handleCloseFilterPopUp } = useContext(Context);
  const { isMediumScreen, isSmallScreen } = useResponsive();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dynamicValues, setDynamicValues] = useState({});
  const [fetchedFields, setFetchedFields] = useState([]);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const getMaidDetails = localStorage.getItem("maidDetails");
  const storedMaidDetails = JSON.parse(getMaidDetails) || {};
  const { serviceId, subCategoryId } = storedMaidDetails;

  useEffect(() => {
    const getServiceFields = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}servicefields/getservicefields`);
        setFetchedFields(response.data.services || []);
      } catch (error) {
        console.error("Error fetching service fields:", error);
        setError("Error fetching service fields.");
      } finally {
        setLoading(false);
      }
    };

    getServiceFields();
  }, []);

  useEffect(() => {
    if (subCategoryId) {
      getDropDownValues(subCategoryId);
    }
  }, [subCategoryId]);

  const getDropDownValues = async (subServiceId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}servicefields/getservicefielddropdownsbysubserviceid?subServiceId=${subServiceId}`
      );
      setDropdownValues(response.data.serviceFields || []);
    } catch (error) {
      console.error("Error fetching dropdown values:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDynamicChange = (fieldName, fieldType) => (event) => {
    let value = event.target.value;
    if (fieldType === "time") {
      const [hours, minutes] = value.split(":");
      const timeObj = dayjs().hour(parseInt(hours, 10)).minute(parseInt(minutes, 10)).second(0);
      value = timeObj.format("HH:mm");
    }

    setDynamicValues((prevValues) => ({ ...prevValues, [fieldName]: value }));

    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[fieldName];
      return newErrors;
    });
  };

  const handleSubmit = async () => {
    const updatedMaidDetails = { ...storedMaidDetails, ...dynamicValues };
    localStorage.setItem("maidDetails", JSON.stringify(updatedMaidDetails));
    // handleCloseFilterPopUp();
    navigate("/maid");
  };

  const getFilteredService = () => {
    if (!fetchedFields || !Array.isArray(fetchedFields)) {
      return null;
    }

    const selectedService = fetchedFields.find(
      (service) => service.serviceId === storedMaidDetails.serviceId
    );

    if (!selectedService) {
      return null;
    }

    for (const category of selectedService.serviceCategory || []) {
      if (category.serviceCategoryId && Array.isArray(category.serviceCategoryId)) {
        if (category.serviceCategoryId.includes(storedMaidDetails.subCategoryId)) {
          localStorage.setItem("serviceFields", JSON.stringify(category));
          return category;
        }
      }
    }
    return null;
  };

  const filteredService = getFilteredService();

  const validateForm = () => {
    const errors = {};
    filteredService?.basicFields?.forEach((field) => {
      if (!dynamicValues[field.keyName]) {
        errors[field.keyName] = true;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Modal
      open={openFilterPopUp}
      onClose={handleCloseFilterPopUp}
      aria-labelledby="filter-modal-title"
      aria-describedby="filter-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "350px" : isMediumScreen ? "400px" : "550px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Typography variant="h6" id="filter-modal-title">
          {selectService}
        </Typography>
        <IconButton aria-label="close"
          onClick={handleCloseFilterPopUp}
          sx={{ position: "absolute", right: 18, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ my: 2, width: "100%" }}>
          <Divider />
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">
            Error loading data
          </Typography>
        ) : filteredService && filteredService.basicFields && filteredService.basicFields.length > 0 ? (
          <Box
            sx={{
              display: isSmallScreen ? "flex" : isMediumScreen ? "" : "flex",
              flexWrap: "wrap",
              gap: isSmallScreen ? "10px" : isMediumScreen ? "24px" : "16px",
              // margin: isSmallScreen ? "10px" : isMediumScreen ? "24px" : "16px",  
              justifyContent: isSmallScreen ? "center" : "flex-start",
            }}
          >
            {filteredService.basicFields.map((field, index) => {
              return (
                <FormControl key={index}
                  sx={{
                    flex: isSmallScreen ? "1 1 100%" : "1 1 calc(50% - 16px)",
                    minWidth: "200px",
                  }}
                  size="small"
                  error={formErrors[field.keyName] !== undefined}
                >
                  {field.type === "select" && (
                    <>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        value={dynamicValues[field.keyName] || ""}
                        onChange={handleDynamicChange(field.keyName, field.type)}
                        label={field.label}
                        required
                      >
                        {Array.isArray(dropdownValues) && dropdownValues.length > 0 ? (
                          dropdownValues
                            .filter(
                              (option) => option.fieldName === field.name && option.isActive
                            )
                            .map((option, optionIndex) => (
                              <MenuItem key={optionIndex} value={option.id}>
                                {option.value}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem disabled>No options available</MenuItem>
                        )}
                      </Select>
                    </>
                  )}
                  {field.type === "date" && (
                    <TextField
                      label={field.label}
                      type="date"
                      size="small"
                      value={dynamicValues[field.keyName] || ""}
                      onChange={handleDynamicChange(field.keyName, field.type)}
                      InputLabelProps={{ shrink: true }}
                      required
                      error={formErrors[field.keyName] !== undefined} // Show error styling
                    />
                  )}
                  {field.type === "time" && (
                    <TextField
                      label={field.label}
                      type="time"
                      sx={{ width: isSmallScreen ? "auto" : isMediumScreen ? "auto" : "48.5%", }}
                      size="small"
                      value={dynamicValues[field.keyName] || ""}
                      onChange={handleDynamicChange(field.keyName, field.type)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }}
                      required
                      error={formErrors[field.keyName] !== undefined}
                    />
                  )}
                </FormControl>
              );
            })}
          </Box>
        ) : (
          <Typography align="center">
            No basic fields available for this service.
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "24px" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              color: "#fff",
              padding: "6px 30px",
              borderRadius: "10px",
              width: isSmallScreen ? "100%" : "150px",
            }}
            onClick={() => {
              if (validateForm()) {
                handleSubmit();
              }
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterPopUp;
