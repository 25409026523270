import React from 'react'
import { Box, Card, CardContent, Avatar, Typography, Button } from '@mui/material';
import BottomNavBar from '../../../components/BottomNavbar';
import { ArrowLeftIcon } from 'lucide-react';
import useResponsive from "../../../hooks/useResponsive";


const Notifications = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();

  return (
    <div>
      <Box display='flex'>

        <ArrowLeftIcon style={{ marginTop: '2px', display: isSmallScreen ? 'flex' : 'none' }} />
        <Typography variant="h5" sx={{ marginBottom: '15px' }}>&nbsp; Notifications</Typography>
      </Box>
      <BottomNavBar />
    </div>
  )
}

export default Notifications