import React, { createContext, useEffect, useState } from "react";
import apiUrl from "../config";
import axios from "axios";

const Context = createContext();

const UserContext = ({ children }) => {
  const [selectService, setSelectService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMorningSlot, setSelectedMorningSlot] = useState(null);
  const [selectedEveningSlot, setSelectedEveningSlot] = useState(null);
  const [selectedCuisineType, setSelectedCuisineType] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [error, setError] = useState(null);
  const [serviceFields, setServiceFields] = useState(null);
  const [category, setCategory] = useState(false);
  const [loadingServices, setLoadingServices] = useState(true);
  const [loadingServiceFields, setLoadingServiceFields] = useState(false);
  const [loadingUserAddresses, setLoadingUserAddresses] = useState(true);
  const [openServicePopUp, setOpenServicePopUp] = useState(false);
  const [openCategoryPopUp, setOpenCategoryPopUp] = useState(false);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [dynamicValues, setDynamicValues] = useState({});
  const [pincode, setPincode] = useState(localStorage.getItem("userPincode") || null);
  const [zoneId, setZoneId] = useState([])
  const [subServices, setSubServices] = useState([]);
  const [userAddress, setUserAddress] = useState([])
  const [favoriteWorkers, setFavoriteWorkers] = useState([])
  const [openLogin, setOpenLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([])
  const userToken = localStorage.getItem("userToken");

  const tokenTimestamp = localStorage.getItem("tokenTimestamp");

  const handleOpenServicePopup = () => {
    setOpenServicePopUp(true);
    setOpenCategoryPopUp(false);
    setOpenFilterPopUp(false);
  };

  const handleCloseServicePopup = () => {
    setOpenServicePopUp(false);
  };

  const handleOpenCategoryPopUp = () => {
    setOpenCategoryPopUp(true);
    setOpenServicePopUp(false);
    setOpenFilterPopUp(false);
  };

  const handleCloseCategoryPopUp = () => {
    setOpenCategoryPopUp(false);
  };

  const handleOpenFilterPopUp = () => {
    setOpenFilterPopUp(true);
    setOpenCategoryPopUp(false);
    // setOpenServicePopUp(false);
  };

  const handleCloseFilterPopUp = () => {
    setOpenFilterPopUp(false);
  };

  const getServices = async () => {
    setLoadingServices(true);
    try {
      const res = await axios.get(`${apiUrl}servicetypes/getservicetypes`);
      setServices(res.data);
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch services.");
      console.error("Error fetching services:", err);
    } finally {
      setLoadingServices(false);
    }
  };

  const getPincodeByLocation = async (latitude, longitude) => {
    try {
      const response = await axios.get(`${apiUrl}zone/getpincode?latitude=${latitude}&longitude=${longitude}`);
      const fetchedPincode = response.data.pin;

      setPincode(fetchedPincode);
      localStorage.setItem("userPincode", fetchedPincode);
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch pincode.");
      console.error("Error fetching pincode:", err);
    }
  };

  const fetchUserLocationAndPincode = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPincodeByLocation(latitude, longitude);
        },
        (err) => {
          console.error("Error fetching location:", err);
          setError("Unable to fetch location.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const GetZoneByPinCode = async () => {
    try {
      const res = await axios.get(apiUrl + `zone/getzonebypincode?pincode=${localStorage.getItem("userPincode")}`)
      setZoneId(res.data.zone)
    } catch (error) {

    }
  }

  const getServicesFields = async (val) => {
    setLoadingServiceFields(true);
    try {
      localStorage.removeItem("serviceFields");
      const response = await axios.get(`${apiUrl}servicefields/getservicefields`);
      const servicesData = response.data.services;

      if (!servicesData) {
        setServiceFields(null);
        setCategory(false);
        return null;
      }

      const currentService = servicesData.find(
        (service) => parseInt(service.serviceId) === parseInt(val)
      );

      if (currentService) {
        if (!currentService.serviceCategory || currentService.serviceCategory.length === 0) {
          localStorage.setItem("serviceFields", JSON.stringify(currentService));
        }

        setServiceFields(currentService);
        setCategory(currentService.serviceCategory && currentService.serviceCategory.length > 0);
        return currentService;
      } else {
        setServiceFields(null);
        setCategory(false);
        return null;
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch service fields.");
      console.error("Error fetching service fields:", err);
    } finally {
      setLoadingServiceFields(false);
    }
  };

  const getUserDetailsByToken = async () => {
    setLoadingUserAddresses(true);
    try {
      const response = await axios.get(apiUrl + "users/getuserbytoken", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserDetails(response.data.userdetails);
      setUserAddress(response.data.addresses);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoadingUserAddresses(false);
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post(`${apiUrl}users/refresh-token`, {
        accessToken: userToken,
        refreshToken: refreshToken,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
        },
      });

      localStorage.setItem("userToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("tokenTimestamp", Date.now());
    } catch (error) {
      setIsUserLoggedIn(false);
    }
  };

  const getFavouriteWorkers = async () => {
    try {
      const response = await axios.get(apiUrl + "users/favoriteworkers", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setFavoriteWorkers(response.data.data)
    } catch (error) {

    }
  }

  const getSubServicesForZoneId = async (serviceId) => {
    setLoading(true);
    try {
      const res = await axios.get(apiUrl + `servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${serviceId}&zoneId=${248}`);
      setSubServices(res.data.subservices);
    } catch (error) {
      console.error("Error fetching sub-services:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const response = await axios.get(apiUrl + "utilities/getstates")
      setStates(response.data.state)
    } catch (error) {

    }
  }

  useEffect(() => {
    getServices();
  }, []);
  // useEffect(() => {
  //   if (!openFilterPopUp) {
  //   }
  // }, [openFilterPopUp]);


  useEffect(() => {

    if (userToken) {
      setIsUserLoggedIn(true);
      getUserDetailsByToken();
    }
  }, [userToken,tokenTimestamp]);

  useEffect(() => {
    if (!pincode) {
      fetchUserLocationAndPincode();
    }
  }, [pincode]);

  useEffect(() => {
    if (pincode) {
      GetZoneByPinCode();
    }
  }, [pincode]);

  const contextValue = {
    selectService,
    setSelectService,
    selectedDate,
    favoriteWorkers,
    setSelectedDate,
    selectedCuisineType,
    setSelectedCuisineType,
    selectedMorningSlot,
    setSelectedMorningSlot,
    selectedEveningSlot,
    setSelectedEveningSlot,
    category,
    services,
    error, userAddress,
    serviceId,
    setServiceId,
    serviceFields,
    getServicesFields,
    loadingServices,
    loadingServiceFields,
    openCategoryPopUp,
    openServicePopUp,
    openFilterPopUp,
    isUserLoggedIn,
    userToken, setIsUserLoggedIn,
    userDetails, loading,
    dynamicValues, zoneId, pincode, subServices, setSubServices,
    setDynamicValues, getSubServicesForZoneId,
    handleOpenCategoryPopUp, getUserDetailsByToken,
    handleOpenServicePopup,
    handleCloseCategoryPopUp,
    handleCloseServicePopup,
    handleOpenFilterPopUp,
    handleCloseFilterPopUp,
    getFavouriteWorkers, getStates, states,
    openLogin, setOpenLogin
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { Context, UserContext };

