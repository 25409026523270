import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import apiUrl from '../../config';
import ViewMoreDetails from '../../popup/MaidPopUps/ViewMoreDetails';
import { Box, Typography, Button, CircularProgress, Snackbar } from "@mui/material";
import dayjs from 'dayjs';
import { Context } from '../../context/Context';
import { Star } from 'lucide-react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HeartAnimation from "../Animations/Heartanimation";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Maid = () => {
    const { selectService, favoriteWorkers, userToken, selectedDate, selectedMorningSlot, getFavouriteWorkers } = useContext(Context);
    const [filteredWorkers, setFilteredWorkers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showMaidDetails, setShowMaidDetails] = useState(false);
    const [selectedMaid, setSelectedMaid] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Toggle favorite state
    const handleHeartClick = async (worker) => {
        const isFavorited = favoriteWorkers?.some(favWorker => favWorker.id === worker.applicationUser.id);
        const newFavoritedStatus = !isFavorited;

        try {
            const apiEndpoint = newFavoritedStatus
                ? `users/addfavoriteworker?workerId=${worker.applicationUser.id}`
                : `users/deletefavoriteworker?workerId=${worker.applicationUser.id}`;

            const response = await axios({
                method: newFavoritedStatus ? 'post' : 'delete',
                url: `${apiUrl}${apiEndpoint}`,
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response.data.success) {
                getFavouriteWorkers();
                setSnackbarMessage(newFavoritedStatus ? 'Pinned to favorites!' : 'Unpinned from favorites!');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error while updating favorite status', error);
            setSnackbarMessage('Something went wrong. Please try again.');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const handleOpenMaidDetails = (worker) => {
        localStorage.setItem("maidDetails", JSON.stringify({ workerUserId: worker.id }));
        setSelectedMaid(worker);
        setShowMaidDetails(true);
    };

    const handleCloseMaidDetails = () => {
        setShowMaidDetails(false);
    };

    const handleFilter = (workers) => {
        const selectedDateTime = dayjs(selectedDate)
            .set('hour', selectedMorningSlot?.hour() || 0)
            .set('minute', selectedMorningSlot?.minute() || 0)
            .utc();
        const offersServiceKey = selectService.toLowerCase();

        const availableWorkers = workers.filter(worker => {
            const offersService = worker.services?.some(service =>
                service.workDetails?.[offersServiceKey]?.enabled
            );

            const hasBookingConflict = worker.bookings?.some(booking => {
                const bookingDateTime = dayjs.utc(`${booking.date}T${booking.time}`);
                return bookingDateTime.isSame(selectedDateTime);
            });

            return offersService && !hasBookingConflict;
        });

        setFilteredWorkers(availableWorkers);
    };

    const fetchWorkers = async () => {
        const maidDetails = JSON.parse(localStorage.getItem("maidDetails")) || {};
        if (loading || !hasMore) return;
        setLoading(true);

        try {
            const response = await axios.post(`${apiUrl}users/searchusers`, {
                subServiceId: maidDetails.subCategoryId,
                pageSize: 10,
                pageNumber,
            });

            const newWorkers = response.data.users || [];
            if (newWorkers.length === 0) {
                setHasMore(false);
            } else {
                setFilteredWorkers(prev => [...prev, ...newWorkers]);
            }
        } catch (error) {
            console.error('Error fetching workers:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchWorkersDebounced = debounce(fetchWorkers, 1000);

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 100
        ) {
            setPageNumber(prev => prev + 1);
            fetchWorkersDebounced();
        }
    };

    useEffect(() => {
        getFavouriteWorkers();
    }, [pageNumber]);
    
    useEffect(() => {
        getFavouriteWorkers();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Box>
            {loading && pageNumber === 1 ? (
                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "90vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant='h5'>Recommended Maid’s</Typography>
                    <Box sx={{
                        mt: 8, maxHeight: 'calc(100vh - 130px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': { display: 'none' },
                    }}>
                        {filteredWorkers.length > 0 ? (
                            filteredWorkers.map((worker, index) => (
                                <Box key={worker.id}>
                                    <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center", border: "1px solid #E4E4E4", borderRadius: "10px", padding: "10px", justifyContent: "space-between", width: "90%", mt: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={worker.profilePicture} alt={worker.name} style={{ width: '100px', height: '100px', borderRadius: '10px', }} />
                                            <Box sx={{ marginLeft: 2 }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography variant='h5'>{worker.applicationUser.firstName} {worker.applicationUser.lastName}</Typography>
                                                    <VerifiedUserIcon sx={{ paddingLeft: "10px", color: "success.main" }} />
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Star color="#FFB700" fill="#FFB700" height={16} width={16} />
                                                    <Typography variant='body1' sx={{ color: "#BCBCBC", paddingLeft: "10px" }}>4.5 Rating</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                            <Button sx={{ border: "1px solid #7E60BF", backgroundColor: "#f0ebfb", width: "90px", height: "35px", ml: 1 }} onClick={() => handleOpenMaidDetails(worker)}> Add</Button>
                                            <Box sx={{ width: '50px', height: '35px', }}>
                                                <Button
                                                    onClick={() => handleHeartClick(worker)}
                                                    startIcon={
                                                        favoriteWorkers?.some(favWorker => favWorker.id === worker.applicationUser.id) ? (
                                                            <FavoriteIcon color="error" sx={{ fontSize: 32, width: '32px', height: '32px' }} />
                                                        ) : (
                                                            <FavoriteBorderIcon sx={{ fontSize: 32, width: '32px', height: '32px' }} />
                                                        )
                                                    }
                                                    disableRipple
                                                    sx={{
                                                        width: '50%',
                                                        height: '100%',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "400px" }}>
                                No profiles are available based on your preference.
                            </Typography>
                        )}

                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={2000}
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', backgroundColor: '#E7F0DC', color: 'white' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', height: '50px', width: '280px' }}>
                                <HeartAnimation triggerAnimation={snackbarOpen} />
                                <p style={{ color: 'black', width: '500px' }}>{snackbarMessage}</p>
                            </div>
                        </Snackbar>
                    </Box>
                    {loading && <CircularProgress />}
                    <ViewMoreDetails open={showMaidDetails} onClose={handleCloseMaidDetails} worker={selectedMaid} />
                </>
            )}
        </Box>
    );
};

export default Maid;
