
import React, { useEffect } from 'react'
import "../../style/EmojiAnimation.css"
import anime from 'animejs/lib/anime.es.js';

const EmojiAnimation = () => {
  useEffect(() => {
    var tl = anime.timeline({
      easing: 'easeOutExpo',
      direction: 'forward',
      loop: true,
    });

    tl.add({
      targets: '#lEye, #rEye',
      translateY: [{ value: 200 }],
      scaleY: [{ value: 0.15 }],
      duration: 150,
    })
      .add({
        targets: '#lEye, #rEye',
        translateY: [{ value: 0 }],
        scaleY: [{ value: 1 }],
        duration: 200,
      })
      .add({
        targets: '#lEye, #rEye',
        translateY: [{ value: 200 }],
        scaleY: [{ value: 0.15 }],
        duration: 300,
      })
      .add({
        targets: '#lEye, #rEye',
        translateY: [{ value: 0 }],
        scaleY: [{ value: 1 }],
        duration: 200,
      })
      .add({
        targets: '#lEye, #rEye, #lBrow, #rBrow, #mouth',
        translateY: [{ value: 70 }],
        translateX: [{ value: -70 }],
        scaleY: [{ value: 1 }],
        duration: 1800,
      }, `-=200`)
      .add({
        targets: '#lEye',
        scaleY: [{ value: 0.2 }],
        scaleX: [{ value: 1.3 }],
        translateY: [{ value: 200 }],
        translateX: [{ value: -150 }],
        duration: 1800,
      }, '-=200')
      .add({
        targets: '#rEye',
        scaleY: [{ value: 0.2 }],
        scaleX: [{ value: 1.3 }],
        translateY: [{ value: 200 }],
        translateX: [{ value: -250 }],
        duration: 1800,
      }, '-=1800')
      .add({
        targets: '#mouth',
        scaleY: [{ value: 0.5 }],
        scaleX: [{ value: 1.2 }],
        translateY: [{ value: 150 }],
        translateX: [{ value: -150 }],
        duration: 1800,
      }, '-=1800')
      .add({
        targets: '#lBrow',
        scaleY: [{ value: 0.8 }],
        rotate: [{ value: -15 }],
        translateY: [{ value: 20 }],
        duration: 1800,
      }, '-=1800')
      .add({
        targets: '#rBrow',
        scaleY: [{ value: 0.8 }],
        rotate: [{ value: 15 }],
        translateY: [{ value: 20 }],
        duration: 1800,
      }, '-=1800')
      .add({
        targets: '#lHand',
        rotate: [{ value: 15, duration: 300 }, { value: -1, duration: 1500 }],
        scaleX: [{ value: 1.16, duration: 1800 }],
        translateX: [{ value: 20, duration: 300 }],
      }, '-=1800')
      .add({
        targets: '#rHand',
        rotate: [{ value: -4, duration: 250 }, { value: 15, duration: 1000 }],
        scaleX: [{ value: 1.1, duration: 1800 }],
        translateX: [{ value: -20, duration: 1000 }],
      }, '-=1800')
      .add({
        targets: '#heart',
        translateY: [{ value: -50 }],
        rotate: [{ value: -10 }],
        scale: [{ value: 0.95 }],
      }, '-=1200')
      .add({
        targets: '#lHand, #rHand',
        rotate: [{ value: 0, duration: 1800 }],
        scaleX: [{ value: 1, duration: 1800 }],
        translateX: [{ value: 0, duration: 1800 }],
      })
      .add({
        targets: '#heart',
        translateY: [{ value: 0 }],
        rotate: [{ value: 0 }],
        scale: [{ value: 1 }],
      }, '-=1800')
      .add({
        targets: '#lEye, #rEye, #lBrow, #rBrow, #mouth',
        translateY: [{ value: 0 }],
        translateX: [{ value: 0 }],
        scaleY: [{ value: 1 }],
        scaleX: [{ value: 1 }],
        rotate: [{ value: 0 }],
        duration: 1800,
      }, '-=1800');

  }, []);
  return (
    <div className="container" style={{ height: "25px", width: '25px' }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        style={{ enableBackground: 'new 0 0 10 10' }}
        xmlSpace="preserve"
      >
        <g>
          <defs>
            <rect id="SVGID_1_" x="30" y="30" width="940" height="940" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st0">
            <linearGradient
              id="faceYellow_1_"
              gradientUnits="userSpaceOnUse"
              x1="500"
              y1="78.53"
              x2="500"
              y2="901.97"
              gradientTransform="matrix(1 0 0 -1 0 1002)"
            >
              <stop offset="0" style={{ stopColor: '#F28A2D' }} />
              <stop offset="1" style={{ stopColor: '#FDE86F' }} />
            </linearGradient>
            <path
              id="faceYellow"
              className="st1"
              d="M970,500c0,258.5-211.5,470-470,470S30,758.5,30,500S241.5,30,500,30S970,241.5,970,500z"
            />
            <radialGradient
              id="faceRed_1_"
              cx="412.376"
              cy="589.624"
              r="58.75"
              gradientTransform="matrix(8 0 0 -8 -2799.0081 5216.9922)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: '#F28A2D', stopOpacity: '0' }} />
              <stop offset="1" style={{ stopColor: '#F08423', stopOpacity: '0.34' }} />
            </radialGradient>
            <path
              id="faceRed"
              className="st2"
              d="M970,500c0,258.5-211.5,470-470,470S30,758.5,30,500S241.5,30,500,30S970,241.5,970,500z"
            />
            <radialGradient
              id="SVGID_3_"
              cx="383.4963"
              cy="1374.8669"
              r="58.75"
              gradientTransform="matrix(1.754 -0.3009 -6.373839e-02 -0.3274 -282.3434 733.1263)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: '#D45F00', stopOpacity: '0.15' }} />
              <stop offset="1" style={{ stopColor: '#F28A2D', stopOpacity: '0' }} />
            </radialGradient>
            <path
              className="st3"
              d="M970,510.5C970,763.2,749.7,970,480.5,970S-9,763.2-9,510.5S211.3,51,480.5,51S970,257.8,970,510.5z"
            />
            <linearGradient
              id="mouth_1_"
              gradientUnits="userSpaceOnUse"
              x1="500"
              y1="660.0775"
              x2="500"
              y2="576.4227"
              gradientTransform="matrix(1 0 0 -1 0 1002)"
            >
              <stop offset="0" style={{ stopColor: '#482314' }} />
              <stop offset="1" style={{ stopColor: '#9A4111' }} />
            </linearGradient>
            <path
              id="mouth"
              className="st4"
              d="M599.9,376.6C594,359,406,359,400.1,376.6s35.3,41.1,99.9,41.1S605.8,394.3,599.9,376.6z"
            />
            <g id="lEye">
              <radialGradient
                id="SVGID_4_"
                cx="467.8636"
                cy="645.4534"
                r="58.75"
                gradientTransform="matrix(1.138695e-16 -1.8596 -1.8596 -1.138695e-16 1523.6304 1150.9828)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: '#3B446B' }} />
                <stop offset="0.688" style={{ stopColor: '#202340' }} />
              </radialGradient>
              <path
                className="st5"
                d="M382.5,270.9c0,41.1-23.5,52.9-58.8,58.8s-64.6-11.8-64.6-58.8c0-35.3,17.6-82.3,64.6-82.3
            C364.9,188.6,382.5,235.6,382.5,270.9z"
              />
              <path
                className="st6"
                d="M317.9,212.1c5.9,5.9,5.9,23.5-5.9,29.4c-5.9,5.9-17.6,11.8-23.5,0s-5.9-17.6,0-29.4
            C300.3,206.3,312,206.3,317.9,212.1z"
              />
            </g>
            {/* ----------------------- */}
            <g id="rEye">
              <radialGradient
                id="SVGID_5_"
                cx="467.899"
                cy="447.5924"
                r="58.75"
                gradientTransform="matrix(1.138695e-16 -1.8596 -1.6906 -1.035225e-16 1427.996 1150.9816)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: '#3B446B' }} />
                <stop offset="0.688" style={{ stopColor: '#202340' }} />
              </radialGradient>
              <path
                className="st7"
                d="M617.5,270.9c0,41.1,21.4,52.9,58.8,58.8c32,5.9,58.8-11.8,58.8-58.8c0-35.3-16-82.3-58.8-82.3
                      S617.5,235.6,617.5,270.9z"
              />
              <path
                className="st6"
                d="M670.9,212.1c5.3,5.9,0,17.6-5.3,29.4c-5.3,5.9-16,5.9-21.4,0c-5.3-5.9,0-17.6,5.3-29.4
                C660.2,200.4,665.6,200.4,670.9,212.1z"
              />
            </g>
            {/* -------------- */}
            <radialGradient
              id="rBrowGradient"
              cx="1101.6755"
              cy="607.2277"
              r="58.7625"
              gradientTransform="matrix(-0.101 -0.3927 -1.8202 0.4683 1540.4578 278.7458)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: "#E38200" }} />
              <stop offset="1" style={{ stopColor: "#CD6700" }} />
            </radialGradient>
            <path
              id="lBrow"
              className="st8"
              d="M229.8,153.4c-11.8,11.8,0,29.4,17.6,23.5c35.3-17.6,105.8-35.3,164.5-29.4
        c17.6,0,23.5,0,17.6-23.5c0-17.6-23.5-29.4-70.5-23.5C288.5,106.4,241.5,141.6,229.8,153.4z"
              fill="url(#rBrowGradient)"
            />
            <radialGradient
              id="lBrowGradient"
              cx="1165.9583"
              cy="521.2252"
              r="58.75"
              gradientTransform="matrix(8.091818e-02 -0.4195 -2.4893 -0.4802 1875.5717 859.3414)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: "#E38200" }} />
              <stop offset="1" style={{ stopColor: "#CD6700" }} />
            </radialGradient>
            <path
              id="rBrow"
              className="st9"
              d="M641,100.5c-47-5.9-70.5,5.9-70.5,23.5c-5.9,23.5,0,23.5,17.6,23.5
        c64.6-5.9,135.1,11.8,164.5,29.4c23.5,11.8,29.4-11.8,17.6-23.5S711.5,106.4,641,100.5z"
              fill="url(#lBrowGradient)"
            />
            <radialGradient
              id="SVGID_6_"
              cx="155.0446"
              cy="562.2991"
              r="58.75"
              gradientTransform="matrix(1.108 -0.4068 -0.5242 -1.4278 273.7917 1431.3267)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: "#9C0600", stopOpacity: 0.5 }} />
              <stop offset="1" style={{ stopColor: "#9C0600", stopOpacity: 0 }} />
            </radialGradient>
            <path
              className="st10"
              d="M599.9,529.4c-123.4-35.3-164.5,47-164.5,47s11.8-94-111.6-135.1c-117.5-35.3-188,76.4-193.9,141
        c-11.8,146.9,117.5,311.4,164.5,370.1c5.9,17.6,23.5,17.6,41.1,17.6c70.5-17.6,270.3-82.3,346.6-211.5
        C711.5,693.9,717.4,564.6,599.9,529.4z"
              fill="url(#SVGID_6_)"
            />

            {/* ================= */}

            <g id="heart">
              <linearGradient
                id="SVGID_7_"
                gradientUnits="userSpaceOnUse"
                x1="426.3219"
                y1="399.4576"
                x2="307.0183"
                y2="15.1103"
                gradientTransform="matrix(1 0 0 -1 0 902)"
              >
                <stop offset="0" style={{ stopColor: "#F34462" }} />
                <stop offset="1" style={{ stopColor: "#CC0820" }} />
              </linearGradient>
              <path className="st11"
                d="M599.9,529.4c-123.4-35.3-164.5,47-164.5,47s11.8-94-111.6-135.1c-117.5-35.3-188,76.4-193.9,141
        c-11.8,146.9,117.5,311.4,164.5,370.1c5.9,17.6,23.5,17.6,41.1,17.6c70.5-17.6,270.3-82.3,346.6-211.5
        C711.5,693.9,717.4,564.6,599.9,529.4z"
                fill="url(#SVGID_7_)"
              />


              <radialGradient
                id="SVGID_8_"
                cx="485.1524"
                cy="526.7298"
                r="58.75"
                gradientTransform="matrix(2.4679 0.9174 0.9174 -2.4679 -1131.1698 1527.2452)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#FF7091", stopOpacity: 0.7 }} />
                <stop offset="1" style={{ stopColor: "#FE6D8E", stopOpacity: 0 }} />
              </radialGradient>
              <path
                className="st12"
                d="M599.9,529.4c-123.4-35.3-164.5,47-164.5,47s11.8-94-111.6-135.1c-117.5-35.3-188,76.4-193.9,141
        c-11.8,146.9,117.5,311.4,164.5,370.1c5.9,17.6,23.5,17.6,41.1,17.6c70.5-17.6,270.3-82.3,346.6-211.5
        C711.5,693.9,717.4,564.6,599.9,529.4z"
                fill="url(#SVGID_8_)"
              />

              <radialGradient
                id="SVGID_9_"
                cx="381.0137"
                cy="519.0494"
                r="58.75"
                gradientTransform="matrix(2.4679 0.9174 0.9174 -2.4679 -1135.7422 1525.9191)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#FF7091", stopOpacity: 0.7 }} />
                <stop offset="1" style={{ stopColor: "#FE6D8E", stopOpacity: 0 }} />
              </radialGradient>
              <path
                className="st13"
                d="M599.9,529.4c-123.4-35.3-164.5,47-164.5,47s11.8-94-111.6-135.1c-117.5-35.3-188,76.4-193.9,141
        c-11.8,146.9,117.5,311.4,164.5,370.1c5.9,17.6,23.5,17.6,41.1,17.6c70.5-17.6,270.3-82.3,346.6-211.5
        C711.5,693.9,717.4,564.6,599.9,529.4z"
                fill="url(#SVGID_9_)"
              />


              <radialGradient
                id="SVGID_10_"
                cx="547.1199"
                cy="576.5929"
                r="58.75"
                gradientTransform="matrix(-0.455 2.2144 3.0492 0.6265 -1245.1776 -851.7267)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#9C0600" }} />
                <stop offset="1" style={{ stopColor: "#9C0600", stopOpacity: 0 }} />
              </radialGradient>
              <path
                className="st14"
                d="M599.9,529.4c-123.4-35.3-164.5,47-164.5,47s11.8-94-111.6-135.1c-117.5-35.3-188,76.4-193.9,141
        c-11.8,146.9,117.5,311.4,164.5,370.1c5.9,17.6,23.5,17.6,41.1,17.6c70.5-17.6,270.3-82.3,346.6-211.5
        C711.5,693.9,717.4,564.6,599.9,529.4z"
                fill="url(#SVGID_10_)"
              />



              <radialGradient
                id="SVGID_11_"
                cx="703.3879"
                cy="525.015"
                r="58.75"
                gradientTransform="matrix(1.1718 0.6939 0.8709 -1.4326 -580.8787 1018.5891)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#9C0600", stopOpacity: 0.5 }} />
                <stop offset="1" style={{ stopColor: "#9C0600", stopOpacity: 0 }} />
              </radialGradient>
              <path
                className="st15"
                d="M599.9,529.4c-123.4-35.3-164.5,47-164.5,47s11.8-94-111.6-135.1c-117.5-35.3-188,76.4-193.9,141
        c-11.8,146.9,117.5,311.4,164.5,370.1c5.9,17.6,23.5,17.6,41.1,17.6c70.5-17.6,270.3-82.3,346.6-211.5
        C711.5,693.9,717.4,564.6,599.9,529.4z"
                fill="url(#SVGID_11_)"
              />
            </g>


            <g id="lHand">

              <radialGradient
                id="SVGID_12_"
                cx="355.8861"
                cy="2.3187"
                r="58.75"
                // gradientTransform="matrix(1.4746 0.6611 -0.5114 1.1406 -378.9044 280.9018)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#F28A2D", stopOpacity: 0.5 }} />
                <stop offset="1" style={{ stopColor: "#F28A2D", stopOpacity: 0 }} />
              </radialGradient>
              <path
                className="st16"
                d="M983,500c0,258.5-211.5,470-470,470S43,758.5,43,500S254.5,30,513,30S983,241.5,983,500z"
                fill="url(#SVGID_12_)"
              />

              <radialGradient
                id="SVGID_13_"
                cx="678.4081"
                cy="26.2063"
                r="58.75"
                gradientTransform="matrix(2.3432 1.9564 -3.9564 5.3432 -382.9348 -2355.3027)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#EDA83A" }} />
                <stop offset="1" style={{ stopColor: "#FFDC5E" }} />
              </radialGradient>
              <path
                className="st17"
                d="M119.4,482.4c-29.4-29.4-82.2-47-88.1,23.5c-5.9,52.9,17.6,146.9,82.3,199.7
        c164.5,129.3,311.4,58.8,317.3-35.3c5.9-70.5-82.3-64.6-105.8-64.6c0,0,0,0,0-5.9c5.9-5.9,17.6-11.8,23.5-17.6
        c23.5-17.6,11.8-47-17.6-41.1c-5.9,0-76.4,23.5-123.4,5.9S154.6,511.8,119.4,482.4z"
                fill="url(#SVGID_13_)"
              />
            </g>

            <g id="rHand">
              <radialGradient
                id="SVGID_14_"
                cx="796.6882"
                cy="-80.7337"
                r="58.75"
                // gradientTransform="matrix(3.5563 -2.8028 1.0197 1.2938 -2034.6986 3059.1223)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#F28A2D", stopOpacity: 0.5 }} />
                <stop offset="1" style={{ stopColor: "#F28A2D", stopOpacity: 0 }} />
              </radialGradient>
              <path
                className="st18"
                d="M959,500c0,258.5-211.5,470-470,470S19,758.5,19,500S230.5,30,489,30S959,241.5,959,500z"
                fill="url(#SVGID_14_)"
              />


              <radialGradient
                id="SVGID_15_"
                cx="814.7936"
                cy="-37.648"
                r="58.75"
                gradientTransform="matrix(8.846 0 0 8.846 -6268.9917 817.5828)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{ stopColor: "#EDA83A" }} />
                <stop offset="1" style={{ stopColor: "#FFDC5E" }} />
              </radialGradient>
              <path
                className="st19"
                d="M859.1,488.3c17.6-35.3,47-23.5,64.6-17.6c23.5,5.9,41.1,23.5,41.1,58.8c0,88.1-11.8,170.4-88.1,252.6
        C741.6,934.8,512.5,911.3,489,817.3c-17.6-70.5,64.6-82.3,94-82.3v-5.9c-11.8-5.9-17.6-11.8-29.4-17.6
        c-23.5-17.6-17.6-52.9,11.8-47c35.3,5.9,82.3,17.6,117.5,11.8C794.5,664.5,812.1,576.4,859.1,488.3z"
                fill="url(#SVGID_15_)"
              />
            </g>


          </g>
        </g>
      </svg>
    </div>

  )
}

export default EmojiAnimation

