import React, { useState } from 'react';
import { Box, Typography, IconButton, Modal, Divider, Button, Grid, LinearProgress, Rating, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Minus, Plus, ShieldCheck } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination } from 'swiper/modules';
import chef from "../../Assets/Images/Chef.png";
import dosa from '../../Assets/Images/daosa.png';
import wada from '../../Assets/Images/wada.png';
import idlly from '../../Assets/Images/idlly.png';
import Bater from '../../Assets/Images/Besanatta.png';
import aadhar from '../../Assets/Images/Aadhar.png';
import pan from '../../Assets/Images/Pan.png';
import police from '../../Assets/Images/Police.png';
import Localaddress from '../../Assets/Images/Localaddress.png';
import video from '../../Assets/Images/video.png';

const reviewsData = {
    rating: 4.5,
    totalReviews: "27k",
    ratingsDistribution: [
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 }
    ],
    reviewers: [
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        }, {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
        {
            name: 'Venkata Raju',
            date: 'Jul 19th, 2024',
            reviewText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            name: 'Raghavendra N.T.R',
            date: 'Aug 28th, 2024',
            reviewText: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        }
    ]
};

const products = [
    {
        id: 1,
        name: 'Dosa Batter',
        price: 89,
        image: Bater,
    },
    {
        id: 2,
        name: 'Cleaning',
        price: 899,
        image: Bater,
    },
    {
        id: 3,
        name: 'Dosa Batter',
        price: 89,
        image: Bater,
    },
];

const verificationSteps = [
    { label: "Aadhar card verification", icon: aadhar },
    { label: "Pan card verification", icon: pan },
    { label: "Police verification", icon: police },
    { label: "Video verification", icon: video },
    { label: "Local Address verification", icon: Localaddress },
];

const maidData = [
    {
        id: 1,
        name: "Rohit Reddy",
        rating: 4.5,
        profilePic: chef,
        verified: true,
        description: "Maid cooks popular foods and custom choices (e.g. Dosa, Idly, Puri, Wada, Omelet, Tea, Coffee, Juice and more).",
        cookingTime: "1 hr 30min",
        languages: ["Telugu", "Hindi", "Tamil"],
        Note: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
];

const dishes = [
    { src: wada, alt: "Wada" },
    { src: dosa, alt: "Dosa" },
    { src: idlly, alt: "Idly" },
    { src: wada, alt: "Wada" },
    { src: wada, alt: "Wada" }
];

const ViewMoreDetails = ({ open, onClose, maid }) => {
    const [visibleCount, setVisibleCount] = useState(10);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        maxHeight: '90%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
    };

    const contentStyle = {
        flex: 1,
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        paddingRight: 1,
    };

    const sectionStyle = {
        mb: 3,
    };

    const handleReadMore = () => {
        setVisibleCount(prevCount => prevCount + 10);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 0' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <img alt={maid?.name} src={maid?.profilePicture} style={{ width: 80, height: 80, marginRight: 12, borderRadius: "5px", objectFit: 'cover', marginBottom: "5px" }} />
                        <Box>
                            <Typography variant="h6" fontWeight="bold">{maid?.firstName}{maid?.lastName} <ShieldCheck fontSize="small" style={{ color: '#0DC07B', height: '18px', width: '16px', marginLeft: '5px', verticalAlign: 'middle' }} /> </Typography>
                            <Box display="flex" alignItems="center" mt={0.5}>
                                <Rating name="read-only" value={maid?.rating} precision={0.5} readOnly size="small" sx={{ mr: 0.5 }} />
                                <Typography variant="body2" color="text.secondary"> {maid?.rating} Rating</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>  <IconButton edge="end" color="inherit" onClick={onClose} sx={{ padding: '0px' }}> <CloseIcon /></IconButton></Box>
                </Box>
                <Box sx={contentStyle}>
                    {maidData.map((maidItem) => (
                        <Box mt={2} key={maidItem?.name}> <Typography variant="body2" gutterBottom> {maidItem?.description} </Typography>
                            <Typography variant="body2">  Cooking est time: {maidItem?.cookingTime} </Typography>
                            <Typography variant="body2" gutterBottom>  Languages: {maidItem.languages.join(', ')} </Typography>
                            <Box variant="caption" mt={1}>
                                <Typography variant="caption" component="span">    Note:</Typography>
                                <Typography variant="caption" sx={{ color: '#BCBCBC', display: 'block' }}> {maidItem.Note}  </Typography>
                            </Box>
                        </Box>
                    ))}
                    <Divider sx={{ my: 2 }} />
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            Maid can cook
                        </Typography>
                        <Box sx={{
                            ...sectionStyle,
                            width: '100%',
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }}   >

                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={10}
                                slidesPerView={isMobile ? 2.5 : 3.5}
                                navigation={false}
                                pagination={false}
                                style={{ touchAction: 'pan-y' }}
                            >
                                {dishes.map((dish, index) => (
                                    <SwiperSlide key={index}>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <img
                                                src={dish.src}
                                                alt={dish.alt}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    borderRadius: '5px',
                                                }}
                                            />
                                            <Typography variant="caption" display="block" mt={0.5}>
                                                {dish.alt}
                                            </Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </Box>
                    <Divider sx={{ border: '2px solid #F4F4F4', my: 1 }} />
                    <Box>   <Typography variant='h5' gutterBottom>
                        Add On's
                    </Typography>
                        <Box sx={{
                            width: '100%',
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }} >
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={10}
                                slidesPerView={1.5}
                                navigation={false}
                                pagination={false}
                            >
                                {products.map((product) => (
                                    <SwiperSlide key={product.id}>
                                        <Box sx={{ p: 1, border: "1px solid #EBEBEB", borderRadius: "5px" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <Box sx={{ display: 'flex', justifyContent: "space-between", gap: "10px" }}>
                                                    <img
                                                        height="45"
                                                        src={product.image}
                                                        alt={product.name}
                                                    />
                                                    <Box>
                                                        <Typography variant="h6" component="div">
                                                            {product.name}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            ₹{product.price}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            border: "1px solid #7E60BF",
                                                            padding: "2px 6px",
                                                            justifyContent: "space-between",
                                                            borderRadius: "5px",
                                                            gap: "10px",
                                                            backgroundColor: "#f0ebfb",
                                                            color: "#7E60BF",
                                                        }}
                                                    >
                                                        <Minus size={15} />
                                                        <Typography variant="body2" color="#7E60BF">
                                                            1
                                                        </Typography>
                                                        <Plus size={15} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </Box>
                    <Divider sx={{ border: '2px solid #F4F4F4', my: 1 }} />
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            We Verified
                        </Typography>
                        <Box sx={{
                            ...sectionStyle,
                            width: '100%',
                            overflow: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }} >
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={isMobile ? 2.5 : 3.5}
                                pagination={{ clickable: true }}
                                navigation={false}
                            >
                                {verificationSteps.map((step, index) => (
                                    <SwiperSlide key={index}>
                                        <Box sx={{ textAlign: 'center', width: "80px" }}>
                                            <img
                                                src={step.icon}
                                                alt={step.label}
                                                style={{ width: '40px', height: '40px', marginBottom: '8px' }}
                                            />
                                            <Typography variant="body2">{step.label}</Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </Box>
                    <Divider sx={{ border: '2px solid #F4F4F4', my: 1 }} />
                    <Box sx={sectionStyle}>
                        <Typography variant="subtitle1" gutterBottom>
                            Reviews
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Rating
                                name="read-only"
                                value={reviewsData.rating}
                                precision={0.5}
                                readOnly
                                size="small"
                                sx={{ mr: 0.5 }}
                            />
                            <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
                                {reviewsData.rating}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {reviewsData.totalReviews} Reviews
                            </Typography>
                        </Box>
                        {reviewsData.ratingsDistribution.map((rating, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                <Typography variant="caption" sx={{ minWidth: 20 }}>
                                    {rating.stars}
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={rating.percentage}
                                    sx={{
                                        flexGrow: 1,
                                        mx: 1,
                                        height: 4,
                                        borderRadius: 2,
                                        backgroundColor: '#e0e0e0',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'primary.main',
                                        },
                                    }}
                                />
                                <Typography variant="caption" color="text.secondary">
                                    {rating.percentage}%
                                </Typography>
                            </Box>
                        ))}
                        <Box sx={{ mt: 2 }}>
                            {reviewsData.reviewers.slice(0, visibleCount).map((reviewer, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs>
                                            <Typography variant="caption" fontWeight="bold">
                                                {reviewer.name}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" display="block">
                                                {reviewer.date}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                        {reviewer.reviewText}
                                    </Typography>
                                    {index < reviewsData.reviewers.length - 1 && <Divider sx={{ mt: 1 }} />}
                                </Box>
                            ))}
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                            <Typography sx={{ color: 'primary.main', cursor: 'pointer' }} variant='caption' onClick={handleReadMore}>
                                Read More
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Footer */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained" sx={{ backgroundColor: 'primary.main', borderRadius: '20px', width: '100%', maxWidth: 200 }} onClick={() => navigate('/checkout')}>  Add to Cart </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ViewMoreDetails;