import React, { useState, useContext } from 'react';
import { Box, Card, CardContent, Avatar, Typography, Button, TextField } from '@mui/material';
import { ArrowLeftIcon, Edit2 } from 'lucide-react';
import profileImage from '../../../Assets/Images/profileImage.png';
import { Context } from '../../../context/Context';
import BottomNavBar from '../../../components/BottomNavbar';
import useResponsive from "../../../hooks/useResponsive";



const profileData = {
    profile: {
        avatarPath: profileImage,
        name: "Shekar Anugula",
        email: "shekar.a@abilioit.com",
        personalInfo: {
            firstName: "Shekar",
            lastName: "Anugula",
            emailID: "shekar.a@abilioit.com",
            mobileNumber: "8142447135",
            address: "Kukatpally, Hyderabad",
        },
    },
};

const EditableField = ({ label, value, name, editMode, onChange, error }) => (
    <Box sx={{ width: '350px', mr: '20px', mb: '15px' }}>
        <Typography variant="body2">{label}</Typography>
        {editMode ? (
            <TextField
                name={name}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error}
                size="small"
                fullWidth
                sx={{ mt: 1 }}
                aria-label={`Edit ${label}`}
            />
        ) : (
            <Typography variant="h6">{value}</Typography>
        )}
    </Box>
);

const ProfileDetails = () => {
    const { userDetails } = useContext(Context) || {};
    const { profile } = profileData;
    const [editMode, setEditMode] = useState(false);
    const [personalInfo, setPersonalInfo] = useState(userDetails);
    const [originalInfo, setOriginalInfo] = useState(userDetails);
    const [errors, setErrors] = useState({});
    const { isSmallScreen, isMediumScreen } = useResponsive();


    const handleEditClick = () => {
        setEditMode((prev) => !prev);
        setErrors({});
    };

    const handleCancel = () => {
        setPersonalInfo(originalInfo);
        setEditMode(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const validate = () => {
        let tempErrors = {};
        if (!personalInfo.firstName) tempErrors.firstName = "First Name is required";
        if (!personalInfo.lastName) tempErrors.lastName = "Last Name is required";
        if (!personalInfo.email) tempErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(personalInfo.email)) tempErrors.email = "Email is not valid";
        if (!personalInfo.phoneNumber) tempErrors.phoneNumber = "Mobile Number is required";
        else if (!/^\d{10}$/.test(personalInfo.phoneNumber)) tempErrors.phoneNumber = "Mobile Number is not valid";
        if (!personalInfo.address) tempErrors.address = "Address is required";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSave = () => {
        if (validate()) {
            setOriginalInfo(personalInfo);
            setEditMode(false);
        }
    };

    return (
        <Box>
            <Box sx={{ mt: 0 }}>
                <Box sx={{ display: 'flex' }}>
                    <ArrowLeftIcon style={{ display: isSmallScreen ? 'flex' : 'none' }} />
                    <Typography variant="h5" sx={{ marginBottom: '15px' }}> &nbsp; My Profile</Typography>
                </Box>
                <Box>
                    <Card
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '20px',
                            marginBottom: '20px',
                            border: '1px solid #E4E4E4',
                            boxShadow: 'none',
                        }}
                    >
                        <Avatar
                            alt={profile.name}
                            src={profile.avatarPath}
                            sx={{ width: 100, height: 100, marginRight: '20px' }}
                        />
                        <CardContent>
                            <Typography variant="h5">{userDetails?.firstName}{userDetails?.lastName}</Typography>
                            <Typography variant="body1">{userDetails?.email || "No email available"}</Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{ padding: '20px', border: '1px solid #E4E4E4', boxShadow: 'none', position: 'relative' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'absolute', top: '10px', right: '10px', gap: '10px' }}>
                            {editMode && (
                                <Button
                                    variant="outlined"
                                    sx={{
                                        minWidth: '80px',
                                        height: '36px',
                                        textTransform: 'capitalize',
                                        fontSize: '14px',
                                        color: 'red',
                                        borderColor: 'red',
                                        '&:hover': {
                                            backgroundColor: '#ffeaea',
                                            borderColor: 'red',
                                        },
                                    }}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                sx={{
                                    minWidth: '80px',
                                    height: '36px',
                                    textTransform: 'capitalize',
                                    fontSize: '14px',
                                }}
                                onClick={editMode ? handleSave : handleEditClick}
                            >
                                <Edit2 style={{ height: '15px' }} /> {editMode ? 'Save' : 'Edit'}
                            </Button>
                        </Box>
                        <Typography variant="h6">Personal Info</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '10px' }}>
                            <EditableField
                                label="First Name"
                                name="firstName"
                                value={personalInfo?.firstName}
                                editMode={editMode}
                                onChange={handleChange}
                                error={errors.firstName}
                            />
                            <EditableField
                                label="Last Name"
                                name="lastName"
                                value={personalInfo?.lastName}
                                editMode={editMode}
                                onChange={handleChange}
                                error={errors.lastName}
                            />
                            <EditableField
                                label="Email ID"
                                name="emailID"
                                value={personalInfo?.email}
                                editMode={editMode}
                                onChange={handleChange}
                                error={errors.email}
                            />
                            <EditableField
                                label="Mobile Number"
                                name="mobileNumber"
                                value={personalInfo?.phoneNumber}
                                editMode={editMode}
                                onChange={handleChange}
                                error={errors.phoneNumber}
                            />
                            {/* <EditableField
                            label="Address"
                            name="address"
                            value={personalInfo?.address}
                            editMode={editMode}
                            onChange={handleChange}
                            error={errors.address}
                        /> */}
                        </Box>
                    </Card>

                </Box>
            </Box>
            <BottomNavBar />
        </Box>
    );
};

export default ProfileDetails;
