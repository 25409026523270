import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, IconButton, Collapse, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import apiUrl from '../config';
import Navbar from '../components/Navbar';
import BottomNavBar from '../components/BottomNavbar';


const Faq = () => {
    const [openId, setOpenId] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const navigate = useNavigate();
    const { isSmallScreen, isMediumScreen } = useResponsive();

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await axios.get(apiUrl + 'faqs/getfaqs?fAQType=WebApp');

                setFaqs(response.data.faqs);
            } catch (error) {
                console.error("Error fetching FAQs:", error);
            }
        };

        fetchFaqs();
    }, []);

    const handleClick = (id) => {
        setOpenId((prevId) => (prevId === id ? null : id));
    };

    const handleNavigateChat = () => {
        navigate('/chat');
    };

    return (
        <>
            <Navbar />
            <BottomNavBar />

            <Container>
                <Box sx={{ display: 'flex', width: isSmallScreen ? '350px' : '650px', justifyContent: 'space-between', alignItems: 'center', padding: isSmallScreen ? '15px' : '0px', mt: 10 }}>
                    <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={() => navigate(-1)}>
                        <ArrowLeftIcon style={{ width: '15px' }} /> Back
                    </Typography>
                    <Typography variant="h5" color="initial" sx={{ fontWeight: '500', textAlign: 'center' }}>
                        May I Help you
                    </Typography>
                </Box>

                {faqs.map((faq) => (
                    <Box key={faq.id} mt={2} sx={{ border: "1px solid #EAECF0", padding: "6px 11px 6px 11px", borderRadius: "8px" }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{faq.question}</Typography>
                            <IconButton onClick={() => handleClick(faq.id)}>
                                {openId === faq.id ? (
                                    <RemoveIcon fontSize='small' sx={{ color: "primary.main", borderRadius: "50%" }} />
                                ) : (
                                    <AddIcon fontSize='small' sx={{ color: "primary.main", borderRadius: "50%" }} />
                                )}
                            </IconButton>
                        </Box>
                        <Collapse in={openId === faq.id}>
                            <Box mt={1}>
                                <Typography variant='h6' sx={{ color: "#667085" }}
                                    component="div"
                                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                                />
                            </Box>
                        </Collapse>
                    </Box>
                ))}

                <Box sx={{ padding: '30px 0 30px 0' }}>
                    <Typography variant="h4" color="initial" sx={{ textAlign: 'center', padding: '15px 0 15px 0' }}>
                        Still have questions?
                    </Typography>
                    <Box sx={{ textAlign: 'center', marginLeft: isSmallScreen ? '0px' : isMediumScreen ? '10px' : '23%' }}>
                        <Typography variant="body1" color="initial" sx={{ display: 'flex', justifyContent: 'center', color: '#BCBCBC', width: isSmallScreen ? '365px' : '650px', textAlign: 'center' }}> If you cannot find the answer to your question in our Help Center, you can always contact us. We will answer you shortly.</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '15px 0 15px 0' }}>
                        <Button variant="contained" sx={{ backgroundColor: 'primary.main', borderRadius: '20px', width: '150px' }} onClick={handleNavigateChat}>
                            Chat Now
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Faq;
