import React, { useContext, useEffect } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import EmojiAnimation from '../Animations/EmojiAnimation';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/Context';
import useResponsive from '../../hooks/useResponsive'; // Importing useResponsive hook

const OtherServices = () => {
    const { subServices, getSubServicesForZoneId, loading } = useContext(Context);
    const serviceId = JSON.parse(localStorage.getItem("maidDetails"));

    const navigate = useNavigate();
    const handleclickBack = () => {
        navigate("/");
    };

    const { isSmallScreen } = useResponsive(); // Accessing isSmallScreen from the hook

    useEffect(() => {
        if (subServices.length === 0) {
            getSubServicesForZoneId(serviceId.serviceId);
        }
    }, []);

    return (
        <Box sx={{ paddingTop: '16px',mt:6 }}>
            <IconButton edge="start" color="inherit" aria-label="back" style={{ paddingTop: "20px" }} onClick={handleclickBack} disableRipple
                disableFocusRipple
            >
                <ArrowLeft height={15} width={15} />
                <Typography variant="body1" component="span" ml={1} fontSize={14} > Back </Typography>
            </IconButton>

            <Typography variant="h4" lineHeight={1.2} fontWeight="bold" my={1}> Transform your space with cleaning hacks  </Typography>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', border: isSmallScreen?"none":'1px solid grey', borderRadius: '8px', padding: 2, width: '80%', maxWidth: '400px', marginTop: "25px" }}  >
                    <Typography variant="h6" fontWeight="medium" mb={2} sx={{ alignSelf: 'flex-start', paddingLeft: '20px', fontWeight: 'bold', fontSize: '18px' }} >   Other Services </Typography>

                    <Box
                        sx={{
                            display: isSmallScreen ? 'flex' : 'flex', // Apply scroll animation only on small screens
                            flexDirection: 'row',
                            flexWrap: 'nowrap', // Ensures no wrapping of items
                            justifyContent: 'flex-start',
                            overflow: 'hidden', // Hide items outside the container
                            width: '100%',
                            animation: isSmallScreen ? 'scrollLeftToRight 10s linear infinite' : 'none' // Apply scroll animation if small screen
                        }}
                    >
                        {subServices.map((service, index) => (
                            <Box key={index} sx={{ textAlign: 'center', margin: 0.5, width: '80px', marginBottom: "18px" }}  >
                                <img src={service.imagePath} alt={service.name} style={{ width: '45px', height: "45px", borderRadius: '8px' }} />
                                <Typography variant="body2" lineHeight={1.2}>{service.name}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}

            <Box sx={{ display: isSmallScreen ? "none" : 'flex', alignItems: 'center', mt: 1, width: '330px' }}>
                <Typography variant="h4" lineHeight={1} fontWeight="bold" sx={{ color: 'rgba(0, 0, 0, 0.1)', width: "80%", marginTop: "20px", }} >
                    We are transparent <span style={{ display: 'flex', gap: '7px' }}>with you. Sevaki  <EmojiAnimation /></span>
                </Typography>
            </Box>

            {/* CSS animation added inline */}
            <style>
                {`
                    @keyframes scrollLeftToRight {
                        0% {
                            transform: translateX(100%);
                        }
                        100% {
                            transform: translateX(-100%);
                        }
                    }

                    .scrollable {
                        display: flex;
                        flex-wrap: nowrap;
                        animation: scrollLeftToRight 10s linear infinite;
                        width: 100%;
                    }
                `}
            </style>
        </Box>
    );
};

export default OtherServices;
