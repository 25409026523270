import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, Button, Chip, Box, Grid, Dialog, DialogContent, Typography, IconButton } from '@mui/material';
import { Search, X, TrendingUp } from 'lucide-react';
import '../App.css';

const TrendingSearch = ({ open, onClose }) => {
    const searchTerms = ["❛maid❜", "❛chef❜", "❛cleaner❜", "❛plumber❜", "❛electrician❜"];
    const [currentTerm, setCurrentTerm] = useState(searchTerms[0]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeout(() => {
                const currentIndex = searchTerms.indexOf(currentTerm);
                const nextIndex = (currentIndex + 1) % searchTerms.length;
                setCurrentTerm(searchTerms[nextIndex]);
            }, 500);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [currentTerm, searchTerms]);

    return (
        <Box sx={{ borderRadius: "10px" }}>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '15px',
                            position: 'relative',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            size='small'
                            placeholder={`Search for ${currentTerm}`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search size={18} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <X size={18} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: '20px',
                                position: 'relative',
                                zIndex: 1
                            }}
                        />

                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='h5' fontWeight="bold" sx={{ marginBottom: "12px" }}>
                                Trending searches
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Chip
                                        icon={<TrendingUp size={18} style={{ color: '#000000' }} />}
                                        label="Maid"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: "5px", border: "none", backgroundColor: "#F3F3F3", color: '#000000',
                                            '& .MuiChip-label': {
                                                color: '#000000',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Chip
                                        sx={{
                                            borderRadius: "5px", border: "none", backgroundColor: "#F3F3F3", color: '#000000',
                                            '& .MuiChip-label': {
                                                color: '#000000',
                                            },
                                        }}
                                        icon={<TrendingUp size={18} style={{ color: '#000000' }} />}
                                        label="Professional Chef"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item>
                                    <Chip
                                        sx={{
                                            borderRadius: "5px", border: "none", backgroundColor: "#F3F3F3", color: '#000000',
                                            '& .MuiChip-label': {
                                                color: '#000000',
                                            },
                                        }}
                                        icon={<TrendingUp size={18} style={{ color: '#000000' }} />}
                                        label="Cleaning and day care"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "90px" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#7E60BF',
                                    color: 'white',
                                    width: "160px",
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#7E60BF',
                                    },
                                    borderRadius: '30px',
                                }}
                            >
                                Search
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TrendingSearch;
