
import React from 'react';
import { Drawer, IconButton, Typography, List, ListItem, ListItemText, Button, Box, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';


import puri from '../Assets/Images/puri.png'
import utensils from '../Assets/Images/utensilsCleaning.png'
import { Trash2 } from 'lucide-react';

const Cart = ({ isDrawerOpen, onClose }) => {
    const [cartItems, setCartItems] = React.useState([
        { id: 1, name: 'Breakfast', price: 100, image: puri },
        { id: 2, name: 'Utensils Cleaning', price: 200, image: utensils },
        { id: 3, name: 'Kitchen Cleaning', price: 300, image: utensils },
    ]);

    const getTotalAmount = () => {
        return cartItems.reduce((total, item) => total + item.price, 0);
    };

    const handleDelete = (id) => {
        setCartItems(cartItems.filter(item => item.id !== id));
    };

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={onClose}
        >
            <div style={{ width: 350, padding: '20px', position: 'relative' }}>
                <IconButton
                    onClick={onClose}
                    style={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography variant="h6" gutterBottom>
                    Your Cart
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '89vh', position: 'relative' }}>
                    <List sx={{ marginBottom: '10px' }}>
                        {cartItems.map(item => (
                            <Box>
                                <ListItem key={item.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={item.image} width={55} height={55} style={{ marginRight: '10px' }} />
                                        <Box>
                                            <ListItemText primary={<Typography sx={{ fontWeight: '600' }}>
                                                {item.name}
                                            </Typography>} secondary={<Typography sx={{ color: '#7E60BF', fontWeight: '800' }}>
                                                ₹{item.price}
                                            </Typography>} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ borderLeft: '1px solid #E4E4E4', paddingLeft: '10px' }}>
                                        <IconButton edge="end" sx={{ color: 'red' }} onClick={() => handleDelete(item.id)}>
                                            <Trash2 style={{ width: '20px' }} />
                                        </IconButton>
                                    </Box>

                                </ListItem>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 10px', marginLeft: '7px' }}>
                                    <CircleIcon sx={{ fontSize: '7px', color: '#E4E4E4' }} />
                                    <Divider sx={{ borderWidth: '0.5px', borderColor: '#E4E4E4', width: '115%' }} />
                                </Box>
                            </Box>


                        ))}

                    </List>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography variant="h5" sx={{ color: '#7E60BF' }}>
                                ₹{getTotalAmount()}
                            </Typography>
                            <a href='' style={{ color: 'black', fontSize: '14px' }}>View Breakup</a>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            style={{ borderRadius: '20px', boxShadow: 'none' }}
                            onClick={() => alert('Proceeding to payment')}
                        >
                            Pay Now
                        </Button>
                    </Box>
                </Box>
            </div>
        </Drawer>
    );
};

export default Cart;
