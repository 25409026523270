import React, { useState } from 'react';
import { Box, Button, TextField, Modal } from '@mui/material';

const Reschedulepopup = ({ open, onClose }) => {
    const [frequency, setFrequency] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('00:00');

    const handleFrequencyChange = (event) => {
        setFrequency(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        boxShadow: 14,
        p: 4,
        outline: 'none',
        borderRadius: '10px',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="form-modal-title"
            aria-describedby="form-modal-description"
        >
            <Box sx={modalStyle}>
                <h2 id="form-modal-title">Update Slot</h2>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent:'space-evenly',
                        }}>
                    {/* Date Input */}
                    <TextField
                        label="Date"
                        type="date"
                        size='small'
                            sx={{width:"180px",height:'40px'}}
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    {/* Time Input */}
                    <TextField
                        label="Time"
                        type="time"
                        size='small'
                        value={selectedTime}
                        sx={{width:"180px",height:'40px'}}
                        onChange={handleTimeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onClose} variant="contained">Confirm</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default Reschedulepopup;
