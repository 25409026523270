import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Button, Typography } from '@mui/material';
import { ArrowLeftIcon, BellIcon, UserCircleIcon, House } from 'lucide-react';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../../components/Header';
import BottomNavBar from '../../../components/BottomNavbar';

const ProfileSummary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const isMainProfilePage = location.pathname === '/profile';

    const getButtonStyles = (route) => {
        return location.pathname === route
            ? {
                color: '#7E60BF',
                backgroundColor: '#f0ebfb',
              }
            : {
                color: 'black',
                backgroundColor: 'transparent',
              };
    };

    if (isSmallScreen) {
        if (!isMainProfilePage) {
            return <Outlet />;
        }

        return (
            <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ margin: '30px 0 15px 0' }}>
                    <Typography
                        variant="h6"
                        color="initial"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeftIcon /> Back
                    </Typography>
                </Box>

                {/* Navigation Buttons */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        component={Link}
                        to="/profile/details"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            ...getButtonStyles('/profile/details'),
                        }}
                        aria-label="View My Profile"
                    >
                        <UserCircleIcon style={{ width: '20px' }} /> My Profile
                    </Button>

                    <Button
                        component={Link}
                        to="/profile/notifications"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            ...getButtonStyles('/profile/notifications'),
                        }}
                        aria-label="View Notifications"
                    >
                        <BellIcon style={{ width: '20px' }} /> Notifications
                    </Button>

                    <Button
                        component={Link}
                        to="/profile/myaddress"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            ...getButtonStyles('/profile/myaddress'),
                        }}
                        aria-label="Manage Addresses"
                    >
                        <House style={{ width: '20px' }} /> My Addresses
                    </Button>

                    <Button
                        component={Link}
                        to="/profile/mobile-change"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            ...getButtonStyles('/profile/mobile-change'),
                        }}
                        aria-label="Change Mobile Number"
                    >
                        <PhoneIphoneIcon style={{ width: '20px' }} /> Mobile Change
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <>
            <Header />
            <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', mt: 7 }}>
                <Box sx={{ margin: '30px 0 15px 0' }}>
                    <Typography
                        variant="h6"
                        color="initial"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeftIcon /> Back
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px',
                            border: '1px solid #E4E4E4',
                            padding: '30px 20px',
                            position: 'sticky',
                            top: 0,
                            height: 'fit-content',
                        }}
                    >
                        <Button
                            component={Link}
                            to="/profile/details"
                            sx={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '10px',
                                boxShadow: 'none',
                                ...getButtonStyles('/profile/details'),
                            }}
                            aria-label="View My Profile"
                        >
                            <UserCircleIcon style={{ width: '20px' }} /> My Profile
                        </Button>

                        <Button
                            component={Link}
                            to="/profile/notifications"
                            sx={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '10px',
                                boxShadow: 'none',
                                ...getButtonStyles('/profile/notifications'),
                            }}
                            aria-label="View Notifications"
                        >
                            <BellIcon style={{ width: '20px' }} /> Notifications
                        </Button>

                        <Button
                            component={Link}
                            to="/profile/myaddress"
                            sx={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '10px',
                                boxShadow: 'none',
                                ...getButtonStyles('/profile/myaddress'),
                            }}
                            aria-label="Manage Addresses"
                        >
                            <House style={{ width: '20px' }} /> My Addresses
                        </Button>

                        <Button
                            component={Link}
                            to="/profile/mobile-change"
                            sx={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '10px',
                                boxShadow: 'none',
                                ...getButtonStyles('/profile/mobile-change'),
                            }}
                            aria-label="Change Mobile Number"
                        >
                            <PhoneIphoneIcon style={{ width: '20px' }} /> Mobile Change
                        </Button>
                    </Box>

                    {/* Scrollable Main Content */}
                    <Box sx={{
                            flexGrow: 1,
                            padding: '0 20px',
                            overflowY: 'auto',
                            height: 'calc(100vh - 70px)',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        <Outlet />
                    </Box>
                </Box>

            </Container>
            <BottomNavBar/>
        </>
    );
};

export default ProfileSummary;
