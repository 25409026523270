import React from 'react'
import { Box, Container } from "@mui/material";
import OtherServices from './OtherServices';
import Maid from './Maid';
import Advertisements from './Advertisements';
import useResponsive from '../../hooks/useResponsive';
import Navbar from '../../components/Navbar';
import BottomNavBar from '../../components/BottomNavbar';

const Total = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive()
    return (
        <>
            <Navbar />
            <BottomNavBar />

            <Container>
                <Box sx={{ display: isSmallScreen ? "block" : "flex", justifyContent: 'space-between' }}>
                    <Box sx={{ width: isSmallScreen ? "100%" : "30%" }}><OtherServices /></Box>
                    <Box sx={{ padding: "10px", width: isSmallScreen ? "100%" : "50%" }}><Maid /></Box>
                    <Box sx={{ width: "20%", display: isSmallScreen ? "none" : "block" }}><Advertisements /></Box>
                </Box>
            </Container>
        </>
    )
}

export default Total