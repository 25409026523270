  import React, { useContext } from "react";
  import { Typography, TextField, Grid, Select, MenuItem, Checkbox, FormControlLabel, InputLabel, ListItemText, FormControl, Radio, Box } from "@mui/material";
  import dayjs from "dayjs";
  import { Context } from "../context/Context";

  const Fields = ({ fields, dropdownValues }) => {
    const { dynamicValues, setDynamicValues } = useContext(Context);

    const handleDynamicChange = (fieldName, fieldType) => (event) => {
      let value = event.target.value;

      if (fieldType === "time") {
        const [hours, minutes] = value.split(":");
        const timeObj = dayjs().hour(parseInt(hours, 10)).minute(parseInt(minutes, 10)).second(0);
        value = timeObj.format("HH:mm");
      }

      if (fieldType === "number") {
        value = Number(value);
      }

      setDynamicValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value
      }));
    };


    const handleCheckboxChange = (fieldName, optionValue, isChecked) => {
      const newValue = dynamicValues[fieldName] || [];
      if (isChecked) {
        setDynamicValues({ ...dynamicValues, [fieldName]: [...newValue, optionValue] });
      } else {
        setDynamicValues({ ...dynamicValues, [fieldName]: newValue.filter((value) => value !== optionValue) });
      }
    };

    return (
      <Box>
        <Grid container spacing={2}>
          {fields?.map((field) => (
            <Grid item xs={12} sm={6} key={field.name}>
              <FormControl fullWidth sx={{ mb: 2 }} size="small">

                {field.type === "select" && (
                  <>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      value={dynamicValues[field.keyName] || ""}
                      onChange={handleDynamicChange(field.keyName, field.type)}
                      label={field.label}
                    >
                      {Array.isArray(dropdownValues) && dropdownValues.length > 0 ? (
                        dropdownValues
                          .filter((option) => option.fieldName === field.name && option.isActive)
                          .map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem disabled>No options available</MenuItem>
                      )}
                    </Select>
                  </>
                )}

                {field.type === "date" && (
                  <TextField
                    label={field.label}
                    type="datetime-local"
                    fullWidth
                    value={dynamicValues[field.keyName] || "00:00"}
                    onChange={handleDynamicChange(field.keyName, field.type)}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                )}
                {field.type === "time" && (
                  <TextField
                    label={field.label}
                    type="time"
                    fullWidth
                    value={dynamicValues[field.keyName] || "00:00"}
                    onChange={handleDynamicChange(field.keyName, field.type)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }}
                    size="small"
                  />
                )}


                {field.type === "number" && (
                  <TextField
                    label={field.label}
                    type="number"
                    fullWidth
                    value={dynamicValues[field.keyName] || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,2}$/.test(value)) {
                        handleDynamicChange(field.keyName, field.type)(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault();
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 0, maxLength: 2 },
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      sx: {
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                )}

                {field.type === "text" && (
                  <TextField
                    label={field.label}
                    type="text"
                    fullWidth
                    value={dynamicValues[field.keyName] || ""}
                    onChange={handleDynamicChange(field.keyName, field.type)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ borderRadius: "10px" }}
                    size="small"
                  />
                )}

                {field.type === "textarea" && (
                  <TextField
                    label={field.label}
                    type="text"
                    fullWidth
                    multiline
                    rows={1}
                    value={dynamicValues[field.keyName] || ""}
                    onChange={handleDynamicChange(field.keyName, field.type)}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      borderRadius: "10px",
                      '& textarea': {
                        resize: 'both',
                        minHeight: '25px',
                        maxHeight: '300px',
                      },
                    }}
                    size="small"
                  />
                )}

                {field.type === "radio" && (
                  <FormControl component="fieldset">
                    <Typography>{field.label}</Typography>
                    <Grid container spacing={1}>
                      {field.options.map((option) => (
                        <Grid item key={option.value}>
                          <FormControlLabel
                            control={<Radio checked={dynamicValues[field.keyName] === option.value} onChange={handleDynamicChange(field.keyName, field.type)} />}
                            label={option.label || option.value}
                            value={option.value}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormControl>
                )}

                {field.type === "checkboxes" && (
                  <>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      label={field.label}
                      multiple
                      value={dynamicValues[field.keyName] || []}
                      onChange={handleDynamicChange(field.keyName, field.type)}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {field.options.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={dynamicValues[field.keyName]?.includes(option.value)}
                            onChange={(event) => handleCheckboxChange(field.keyName, option.value, event.target.checked)}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {field.type === "multiselect" && (
                  <>
                    <InputLabel component="legend" id="multilanguage">
                      {field.label}
                    </InputLabel>
                    <Select
                      label={field.label}
                      multiple
                      value={dynamicValues[field.keyName] || []}
                      onChange={handleDynamicChange(field.keyName, field.type)}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {Array.isArray(dropdownValues) && dropdownValues.length > 0 ? (
                        dropdownValues
                          .filter((option) => option.fieldName === field.name && option.isActive)
                          .map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              <Checkbox
                                checked={dynamicValues[field.keyName]?.includes(option.value)}
                                onChange={(event) => handleCheckboxChange(field.keyName, option.value, event.target.checked)}
                              />
                              <ListItemText primary={option.value} />
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem disabled>No options available</MenuItem>
                      )}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  export default Fields;


//   import React, { useContext } from "react";
// import {
//   Typography,
//   TextField,
//   Grid,
//   Select,
//   MenuItem,
//   Checkbox,
//   FormControlLabel,
//   InputLabel,
//   ListItemText,
//   FormControl,
//   Radio,
//   Box
// } from "@mui/material";
// import dayjs from "dayjs";
// import { Context } from "../context/Context";

// const Fields = ({ fields, dropdownValues }) => {
//   const { dynamicValues, setDynamicValues } = useContext(Context);

//   const handleDynamicChange = (fieldName, fieldType) => (event) => {
//     let value = event.target.value;

//     if (fieldType === "time") {
//       const [hours, minutes] = value.split(":");
//       const timeObj = dayjs().hour(parseInt(hours, 10)).minute(parseInt(minutes, 10)).second(0);
//       value = timeObj.format("HH:mm");
//     }

//     if (fieldType === "number") {
//       value = Number(value);
//     }

//     setDynamicValues((prevValues) => ({
//       ...prevValues,
//       [fieldName]: value
//     }));
//   };

//   const handleCheckboxChange = (fieldName, optionValue, isChecked) => {
//     const newValue = dynamicValues[fieldName] || [];
//     if (isChecked) {
//       setDynamicValues({ ...dynamicValues, [fieldName]: [...newValue, optionValue] });
//     } else {
//       setDynamicValues({ ...dynamicValues, [fieldName]: newValue.filter((value) => value !== optionValue) });
//     }
//   };

//   return (
//     <Box>
//       <Grid container spacing={2}>
//         {fields?.map((field) => (
//           <Grid item xs={12} sm={6} key={field.name}>
//             <FormControl fullWidth sx={{ mb: 2 }} size="small">
//               {field.type === "select" && (
//                 <>
//                   <InputLabel>{field.label}</InputLabel>
//                   <Select
//                     value={dynamicValues[field.keyName] || ""}
//                     onChange={handleDynamicChange(field.keyName, field.type)}
//                     label={field.label}
//                   >
//                     {Array.isArray(dropdownValues) && dropdownValues.length > 0 ? (
//                       dropdownValues
//                         .filter((option) => option.fieldName === field.name && option.isActive)
//                         .map((option) => (
//                           <MenuItem key={option.value} value={option.value}>
//                             {option.value}
//                           </MenuItem>
//                         ))
//                     ) : (
//                       <MenuItem disabled>No options available</MenuItem>
//                     )}
//                   </Select>
//                 </>
//               )}

//               {field.type === "date" && (
//                 <TextField
//                   label={field.label}
//                   type="datetime-local"
//                   fullWidth
//                   value={dynamicValues[field.keyName] || ""}
//                   onChange={handleDynamicChange(field.keyName, field.type)}
//                   InputLabelProps={{ shrink: true }}
//                   size="small"
//                 />
//               )}

//               {field.type === "time" && (
//                 <TextField
//                   label={field.label}
//                   type="time"
//                   fullWidth
//                   value={dynamicValues[field.keyName] || "00:00"}
//                   onChange={handleDynamicChange(field.keyName, field.type)}
//                   InputLabelProps={{ shrink: true }}
//                   inputProps={{ step: 300 }}
//                   size="small"
//                 />
//               )}

//               {field.type === "number" && field.label === "Number of People" && (
//                 <FormControl fullWidth sx={{ mb: 2 }} size="small">
//                   <InputLabel>{field.label}</InputLabel>
//                   <Select
//                     value={dynamicValues[field.keyName] || ""}
//                     onChange={handleDynamicChange(field.keyName, field.type)}
//                     label={field.label}
//                   >
//                     {Array.from({ length: 20 }, (_, i) => i + 1).map((value) => (
//                       <MenuItem key={value} value={value}>
//                         {value}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               )}

//               {field.type === "number" && field.label !== "Number of People" && (
//                 <TextField
//                   label={field.label}
//                   type="number"
//                   fullWidth
//                   value={dynamicValues[field.keyName] || ""}
//                   onChange={(e) => {
//                     const value = e.target.value;
//                     if (/^\d{0,2}$/.test(value)) {
//                       handleDynamicChange(field.keyName, field.type)(e);
//                     }
//                   }}
//                   onKeyDown={(e) => {
//                     if (e.key === "ArrowUp" || e.key === "ArrowDown") {
//                       e.preventDefault();
//                     }
//                   }}
//                   InputProps={{
//                     inputProps: { min: 0, maxLength: 2 },
//                     inputMode: "numeric",
//                     pattern: "[0-9]*",
//                     sx: {
//                       "& input[type=number]": {
//                         MozAppearance: "textfield"
//                       },
//                       "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
//                         WebkitAppearance: "none",
//                         margin: 0
//                       }
//                     }
//                   }}
//                   InputLabelProps={{ shrink: true }}
//                   size="small"
//                 />
//               )}

//               {field.type === "text" && (
//                 <TextField
//                   label={field.label}
//                   type="text"
//                   fullWidth
//                   value={dynamicValues[field.keyName] || ""}
//                   onChange={handleDynamicChange(field.keyName, field.type)}
//                   InputLabelProps={{ shrink: true }}
//                   sx={{ borderRadius: "10px" }}
//                   size="small"
//                 />
//               )}

//               {field.type === "textarea" && (
//                 <TextField
//                   label={field.label}
//                   type="text"
//                   fullWidth
//                   multiline
//                   rows={1}
//                   value={dynamicValues[field.keyName] || ""}
//                   onChange={handleDynamicChange(field.keyName, field.type)}
//                   InputLabelProps={{ shrink: true }}
//                   sx={{
//                     borderRadius: "10px",
//                     "& textarea": {
//                       resize: "both",
//                       minHeight: "25px",
//                       maxHeight: "300px"
//                     }
//                   }}
//                   size="small"
//                 />
//               )}

//               {field.type === "radio" && (
//                 <FormControl component="fieldset">
//                   <Typography>{field.label}</Typography>
//                   <Grid container spacing={1}>
//                     {field.options.map((option) => (
//                       <Grid item key={option.value}>
//                         <FormControlLabel
//                           control={
//                             <Radio
//                               checked={dynamicValues[field.keyName] === option.value}
//                               onChange={handleDynamicChange(field.keyName, field.type)}
//                             />
//                           }
//                           label={option.label || option.value}
//                           value={option.value}
//                         />
//                       </Grid>
//                     ))}
//                   </Grid>
//                 </FormControl>
//               )}

//               {field.type === "checkboxes" && (
//                 <>
//                   <InputLabel>{field.label}</InputLabel>
//                   <Select
//                     label={field.label}
//                     multiple
//                     value={dynamicValues[field.keyName] || []}
//                     onChange={handleDynamicChange(field.keyName, field.type)}
//                     renderValue={(selected) => selected.join(", ")}
//                   >
//                     {field.options.map((option) => (
//                       <MenuItem key={option.id} value={option.value}>
//                         <Checkbox
//                           checked={dynamicValues[field.keyName]?.includes(option.value)}
//                           onChange={(event) =>
//                             handleCheckboxChange(field.keyName, option.value, event.target.checked)
//                           }
//                         />
//                         <ListItemText primary={option.value} />
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </>
//               )}

//               {field.type === "multiselect" && (
//                 <>
//                   <InputLabel component="legend" id="multilanguage">
//                     {field.label}
//                   </InputLabel>
//                   <Select
//                     label={field.label}
//                     multiple
//                     value={dynamicValues[field.keyName] || []}
//                     onChange={handleDynamicChange(field.keyName, field.type)}
//                     renderValue={(selected) => selected.join(", ")}
//                   >
//                     {Array.isArray(dropdownValues) && dropdownValues.length > 0 ? (
//                       dropdownValues
//                         .filter((option) => option.fieldName === field.name && option.isActive)
//                         .map((option) => (
//                           <MenuItem key={option.id} value={option.value}>
//                             <Checkbox
//                               checked={dynamicValues[field.keyName]?.includes(option.value)}
//                               onChange={(event) =>
//                                 handleCheckboxChange(field.keyName, option.value, event.target.checked)
//                               }
//                             />
//                             <ListItemText primary={option.value} />
//                           </MenuItem>
//                         ))
//                     ) : (
//                       <MenuItem disabled>No options available</MenuItem>
//                     )}
//                   </Select>
//                 </>
//               )}
//             </FormControl>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default Fields;
